import React from "react";
import Tag from "../UI/Tag";

type Props = {
  header: string;
  subHeader: string;
  headerTag?: string;
};

export default function DetailHeader({ header, subHeader, headerTag }: Props) {
  return (
    <div className="mt-8 text-left">
      <h3 className="flex flex-row gap-2 text-lg font-semibold text-primaryText">
        {header}
        {headerTag && <Tag text={headerTag} />}
      </h3>
      <p className="text-sm font-normal text-secondaryText">{subHeader}</p>
    </div>
  );
}

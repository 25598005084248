import { Alchemy, AlchemyProvider, Network, Nft } from "alchemy-sdk";
import { ethers } from "ethers";
import React, { createContext, useContext, useEffect, useState } from "react";

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_API!, // Replace with your Alchemy API Key.
  network:
    process.env.REACT_APP_NETWORK == "test"
      ? Network.ETH_GOERLI
      : Network.ETH_MAINNET, // Replace with your network.
};
export const alchemy = new Alchemy(settings);

declare global {
  interface Window {
    ethereum: any;
  }
}

type Web3Context = {
  connectWallet: () => Promise<void>;
  web3CapableBrowser: boolean;
  account?: string;
  setContractAddress: (contract?: string) => void;
  contractAddress?: string;
  web3User?: ethers.providers.Web3Provider | AlchemyProvider;
  alchemyProvider?: AlchemyProvider;
  setAccount: (account?: string) => void;
  nfts: Nft[];
  signMessage: (nonce: string) => Promise<string | undefined>;
};

const context = createContext<Web3Context>(null!);

type Props = React.PropsWithChildren<{}>;

export enum Connection {
  CONNECTED,
  REJECTED,
  NOT_INSTALLED,
}

export const Web3Provider = ({ children }: Props) => {
  const [account, setAccount] = useState<string>();
  const [contractAddress, setContractAddress] = useState<string>();
  const [nfts, setNfts] = useState<Nft[]>([]);
  const [web3User, setWeb3User] = useState<ethers.providers.Web3Provider>();
  const [alchemyProvider, setAlchemyProvider] = useState<AlchemyProvider>();

  const onAccountsChanged = (accounts: string[]) => {
    if (accounts.length == 0) {
      setAccount(undefined);
    }
  };

  const getAccounts = async (web3User: ethers.providers.Web3Provider) => {
    window.ethereum.on("accountsChanged", onAccountsChanged);
    const accounts = await web3User.listAccounts();
    const account = accounts?.[0];
    setAccount?.(account);
  };

  const connectWallet = async () => {
    if (web3User) {
      try {
        const accounts = await web3User.send("eth_requestAccounts", []);
        setAccount(accounts[0]);
      } catch (error) {
        setAccount(undefined);
      }
    } else {
      // The user doesn't have Metamask installed.
      setAccount(undefined);
    }
  };

  const getNfts = async () => {
    if (account && contractAddress) {
      const nfts = await alchemy.nft.getNftsForOwner(account, {
        contractAddresses: [contractAddress],
      });
      setNfts(nfts.ownedNfts);
    } else {
      setNfts([]);
    }
  };

  const signMessage = async (message: string) => {
    if (account && web3User) {
      const signature = await web3User.getSigner(account).signMessage(message);
      return signature;
    }
  };

  const loadAccountData = async () => {
    if (account && web3User) {
      const balance = await web3User.getBalance(account);
      getNfts();
    }
  };

  const loadInitialData = async () => {
    const alchemyProvider = await alchemy.config.getProvider();
    setAlchemyProvider(alchemyProvider);

    const web3User = window.ethereum
      ? new ethers.providers.Web3Provider(window.ethereum)
      : undefined;
    setWeb3User(web3User);

    if (web3User) {
      getAccounts(web3User);
    }
  };

  useEffect(() => {
    loadAccountData();
  }, [account, contractAddress]);

  /// try to connect wallet
  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <context.Provider
      value={{
        connectWallet,
        web3CapableBrowser: window.ethereum,
        account,
        web3User,
        alchemyProvider,
        setAccount,
        setContractAddress,
        contractAddress,
        nfts,
        signMessage,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useWeb3 = () => useContext(context);

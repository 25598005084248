import React from "react";
import { array } from "../../util";

type Props = {
  className?: string;
  rows: number;
};

export const TableLoadingIndicator: React.FC<Props> = ({ className, rows }) => {
  return (
    <>
      {array(0, rows).map((i) => (
        <div
          key={i}
          className="my-6 mx-6 h-8 animate-pulse rounded-lg bg-gray50"
        />
      ))}
    </>
  );
};

import { Dialog, Transition } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

import {
  CreditCardIcon,
  XMarkIcon,
  Cog8ToothIcon,
  HomeIcon,
  GiftIcon,
  Bars3Icon,
  QuestionMarkCircleIcon,
  Square3Stack3DIcon,
  UsersIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { classNames } from "../util";
import { User } from "../API/data-contracts";
import Tag from "./UI/Tag";

const navigation = [
  {
    name: "Home",
    href: "/",
    icon: HomeIcon,
    current: false,
  },
  {
    name: "Products",
    href: "/products",
    icon: Square3Stack3DIcon,
    current: false,
  },
  {
    name: "Rewards",
    href: "/rewards",
    icon: GiftIcon,
    current: false,
  },
  {
    name: "Claims",
    href: "/claims",
    icon: UsersIcon,
    current: false,
    count: -1,
  },
  {
    name: "Admin",
    href: "/admin",
    icon: Bars3Icon,
    current: false,
    auth: "admin",
  },
];
const bottomNavigation = [
  {
    name: "menu.support",
    icon: QuestionMarkCircleIcon,
    current: false,
    onClick: () => {
      window.open("mailto:hello@nftclaimer.xyz");
    },
  },
  {
    name: "menu.settings",
    href: "/settings",
    icon: Cog8ToothIcon,
    current: false,
  },
  // {
  //   name: "menu.plansBilling",
  //   href: "/billing",
  //   icon: CreditCardIcon,
  //   current: false,
  // },
];

type Props = {};

const SidebarLayout: React.FC<Props> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [me, setMe] = useState<User | undefined>();
  const { api } = useAuth();
  useEffect(() => {
    api.me().then((res) => setMe(res.data));
  }, []);

  const { logout } = useAuth();

  const [claimCount, setClaimCount] = useState(-1);

  useEffect(() => {
    api.getUserClaimCounts().then((res) => {
      setClaimCount(res.data.claimed);
    });
  }, []);

  const location = useLocation();

  navigation.forEach((n) => (n.current = location.pathname === n.href));
  navigation.forEach((n) => {
    if (n.href === "/claims") {
      n.count = claimCount;
    }
  });

  bottomNavigation.forEach((n) => (n.current = location.pathname === n.href));

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-sidebarBackground pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 flex-row items-center gap-2.5 px-6">
                  <img src="images/logo.svg" alt="logo" className="h-5" />
                  <p className="font-spaceGrotesk text-lg font-bold tracking-tighter text-white">
                    nftclaimer.xyz
                  </p>
                </div>
                <div className="mt-14 flex flex-grow flex-col">
                  {/* Main Nav */}
                  <nav className="flex-1 space-y-1 px-2">
                    {navigation.map((item) => {
                      if (
                        item.auth === "admin" &&
                        (me === undefined || me?.admin === false)
                      ) {
                        return <></>;
                      }
                      return <SidebarElement key={item.name} {...item} />;
                    })}
                  </nav>

                  {/* Help/Settings/Billing Info */}
                  <nav className="space-y-1 px-2 pb-6">
                    {bottomNavigation.map((item) => (
                      <SidebarElement key={item.name} {...item} />
                    ))}
                  </nav>

                  <div className="mx-4 h-[1px] bg-sidebarButtonHighlight" />

                  {/* Account Info */}
                  <div className="flex w-full flex-col px-4 pt-6 pb-8">
                    <div className="flex w-full flex-row items-center justify-between font-medium text-white">
                      <div className="flex items-center justify-center gap-2">
                        {me?.username ?? "-"}
                        {/* {me?.verifiedContractOwner && (
                          <CheckBadgeIcon className="h-4 text-blue-500" />
                        )} */}
                      </div>
                      <ArrowRightOnRectangleIcon
                        className="h-5 cursor-pointer text-gray400"
                        onClick={logout}
                      />
                    </div>
                    <div className="w-full text-left text-xs font-normal text-white opacity-70 line-clamp-1">
                      {me?.contractAddress ?? "-"}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-sidebarBackground pt-5">
            <div className="flex flex-shrink-0 flex-row items-center gap-2.5 px-6">
              <img src="images/logo.svg" alt="logo" className="h-5" />
              <p className="font-spaceGrotesk text-lg font-bold tracking-tighter text-white">
                nftclaimer.xyz
              </p>
            </div>
            <div className="mt-14 flex flex-grow flex-col">
              {/* Main Nav */}
              <nav className="flex-1 space-y-1 px-2">
                {navigation.map((item) => {
                  if (
                    item.auth === "admin" &&
                    (me === undefined || me?.admin === false)
                  ) {
                    return <></>;
                  }
                  return <SidebarElement key={item.name} {...item} />;
                })}
              </nav>

              {/* Help/Settings/Billing Info */}
              <nav className="space-y-1 px-2 pb-6">
                {bottomNavigation.map((item) => (
                  <SidebarElement key={item.name} {...item} />
                ))}
              </nav>

              <div className="mx-4 h-[1px] bg-sidebarButtonHighlight" />

              {/* Account Info */}
              <div className="flex w-full flex-col px-4 pt-6 pb-8">
                <div className="flex w-full flex-row items-center justify-between font-medium text-white">
                  <div className="flex items-center justify-center gap-2">
                    {me?.projectDisplayName ?? "-"}
                    {/* {me?.verifiedContractOwner && (
                      <CheckBadgeIcon className="h-4 text-blue-500" />
                    )} */}
                  </div>
                  <ArrowRightOnRectangleIcon
                    className="h-5 cursor-pointer text-gray400"
                    onClick={logout}
                  />
                </div>
                <div className="w-full text-left text-xs font-normal text-white opacity-70 line-clamp-1">
                  {me?.contractAddress ?? "No contract address set"}
                </div>
                {me?.username && (
                  <div className="w-full text-left text-xs font-normal text-white opacity-70 line-clamp-1">
                    {me.username}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="lg:pl-64">
          <div className="mx-auto flex flex-col">
            <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:invisible lg:h-0 lg:px-8">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <main className="flex h-full w-full bg-primaryBackground px-8 py-6">
              {children ? children : <Outlet />}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

type ElementProps = {
  name: string;
  href?: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
  onClick?: () => void;
  count?: number;
};

function SidebarElement(item: ElementProps) {
  const { t } = useTranslation();

  return (
    <Link
      to={item.href ?? ""}
      className={classNames(
        item.current
          ? "bg-sidebarButtonHighlight text-white"
          : "text-white hover:bg-sidebarButtonHighlight hover:text-white",
        "group mx-2 flex items-center rounded-md py-2 px-2 text-sm font-medium"
      )}
      onClick={() => item.onClick && item.onClick()}
    >
      <item.icon
        className={classNames(
          item.current ? "text-white" : "text-white group-hover:text-white",
          "mr-4 h-6 w-6 flex-shrink-0 lg:mr-3"
        )}
        aria-hidden="true"
      />
      <div className="flex h-fit w-full flex-row items-center justify-between">
        {t(item.name)}
        {item.count !== undefined && item.count >= 0 && (
          <Tag
            className="ml-2"
            text={item.count.toFixed()}
            style={"gray"}
            small
          />
        )}
      </div>
    </Link>
  );
}

export default SidebarLayout;

import {
  CloudArrowUpIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { init, t } from "i18next";
import { SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../API/AuthContext";
import { Product } from "../../API/data-contracts";
import { useError } from "../../Error/ErrorContext";
import { classNames } from "../../util";
import Button, { ButtonStyle } from "../UI/Button";
import { ImageUploadInput } from "../UI/ImageUploadInput";
import Modal from "../../GeneralComponents/Modal";
import { Photo } from "../UI/Photo";
import Separator from "../UI/Separator";

type Props = {
  isOpen: boolean;
  close: (reload: boolean) => void;
  initialData?: Product;
};

export default function NewProductDialog({
  isOpen,
  close,
  initialData,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setError } = useError();
  const { api } = useAuth();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const addProduct = async (data: any) => {
    try {
      const response = await api.addUserProduct(
        {
          title: data.title,
          description: data.description,
        },
        {
          secure: true,
        }
      );

      if (response.ok) {
        //now upload the images
        const id = response.data.id;
        if (id && imagesToUpload.length > 0) {
          for (const image of imagesToUpload) {
            await api.addProductImage(id, { image });
          }
        }

        close(true);
        // remove old data after closing
        setImagesToUpload([]);
        setImageIdsToDelete([]);
        setImages([]);
      } else if (response.error) {
        setError(response.error);
      }
    } catch (error) {
      setError(error);
    }
  };

  const editProduct = async (data: any) => {
    setIsSaveLoading(true);
    //delete images from imageIdsToDelete
    if (imageIdsToDelete.length > 0 && initialData) {
      for (const id of imageIdsToDelete) {
        await api.deleteProductImage(initialData?.id, id);
      }
    }

    //upload images
    if (imagesToUpload.length > 0 && initialData) {
      for (const image of imagesToUpload) {
        await api.addProductImage(initialData?.id, { image });
      }
    }

    try {
      const response = await api.updateUserProduct(initialData?.id ?? "", {
        title: data.title,
        description: data.description,
      });
      if (response.ok) {
        close(true);
        // remove old data after closing
        setImagesToUpload([]);
        setImageIdsToDelete([]);
        setImages([]);
      } else if (response.error) {
        setError(response.error);
      }
    } catch (error) {
      setError(error);
    }
    setIsSaveLoading(false);
  };

  const [imageIdsToDelete, setImageIdsToDelete] = useState<string[]>([]);
  const [imagesToUpload, setImagesToUpload] = useState<File[]>([]);
  const [images, setImages] = useState<{ file?: File; id?: string }[]>([]);
  useEffect(() => {
    // set initial images
    if (initialData?.imageIds) {
      setImages(initialData.imageIds.map((id) => ({ id })));
    }
  }, [initialData]);

  const errorStyle =
    "border-red-600 text-red-600 focus:border-red-600 focus:ring-red-600";
  const normalStyle = "border-gray-300 focus:border-accent focus:ring-accent";
  return (
    <Modal
      title={
        initialData ? t("product.editHeadline") : t("product.createHeadline")
      }
      subTitle={
        initialData
          ? t("product.editSubHeadline")
          : t("product.createSubHeadline")
      }
      isOpen={isOpen}
      closeModal={() => close(false)}
    >
      <>
        <div className="px-6 pt-8 pb-10">
          {/* Product Image Upload Field */}
          <label className="block text-sm font-medium text-gray700">
            {t("product.productImage")}
          </label>
          <ImageUploadInput
            imagesToUpload={imagesToUpload}
            setImagesToUpload={setImagesToUpload}
            images={images}
            setImages={setImages}
            imageIdsToDelete={imageIdsToDelete}
            setImageIdsToDelete={setImageIdsToDelete}
          />

          {/* Title Text Input */}
          <label className="mt-6 block text-sm font-medium text-gray700">
            {t("product.title")}
          </label>
          <div className="sm:col-span-2">
            <input
              id="title"
              type="text"
              defaultValue={initialData?.title ?? ""}
              placeholder={t("product.titlePlaceholder")}
              className={classNames(
                errors["title"] ? errorStyle : normalStyle,
                "mt-1.5 block w-full flex-1 rounded-md shadow-sm sm:text-sm"
              )}
              {...register("title", { required: true })}
            />
          </div>

          {/* Description Text Area */}
          <label className="mt-6 block text-sm font-medium text-gray700">
            {t("product.description")}
          </label>
          <div className="sm:col-span-2">
            <textarea
              id="description"
              rows={3}
              defaultValue={initialData?.description ?? ""}
              placeholder={t("product.descriptionPlaceholder")}
              className={classNames(
                errors["description"] ? errorStyle : normalStyle,
                "mt-1.5 block w-full rounded-md shadow-sm sm:text-sm"
              )}
              {...register("description", { required: true })}
            />
          </div>
        </div>
        <Separator />
        <div className="flex justify-end gap-2 p-4">
          <Button
            text={t("buttons.cancel")}
            style={ButtonStyle.OUTLINED}
            onClick={() => close(false)}
          />
          <Button
            text={
              initialData ? t("buttons.saveChanges") : t("buttons.addProduct")
            }
            style={ButtonStyle.FILLED}
            onClick={
              initialData ? handleSubmit(editProduct) : handleSubmit(addProduct)
            }
            loading={isSaveLoading}
          />
        </div>
      </>
    </Modal>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { User } from "./data-contracts";

type AuthContext = {
  user?: User;
  refreshUser: () => void;
};

const context = createContext<AuthContext>(null!);

type Props = React.PropsWithChildren<{}>;

export default function UserContext({ children }: Props) {
  const { api } = useAuth();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [refresh, setRefresh] = useState(0);
  function refreshUser() {
    setRefresh((prev) => prev + 1);
  }

  useEffect(() => {
    api
      .me()
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [api, refresh]);

  return (
    <context.Provider
      value={{
        user,
        refreshUser,
      }}
    >
      {children}
    </context.Provider>
  );
}

export const useUser = () => useContext(context);

import React from "react";

export type Step = {
  headline: string;
  subtitle: string;
  onClick: () => void;
  status: "complete" | "current" | "upcoming";
};

type Props = {
  steps: Step[];
  enabledSteps?: number;
};

export default function ProgressSteps({ steps, enabledSteps = 0 }: Props) {
  return (
    <nav aria-label="Progress" className="mt-10 w-full">
      <ol role="list" className="md:flex">
        {steps.map((step, i) => (
          <li key={step.subtitle} className="md:flex-1">
            {step.status === "complete" ? (
              <button
                disabled={i >= enabledSteps + 1}
                onClick={step.onClick}
                className="group flex w-full flex-col border-l-4 border-accent700 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-1 md:pb-0"
              >
                <span className="mt-1 text-sm font-semibold text-accent700">
                  {step.headline}
                </span>
                <span className="text-sm font-normal  text-accent600">
                  {step.subtitle}
                </span>
              </button>
            ) : step.status === "current" ? (
              <button
                disabled={i >= enabledSteps + 1}
                onClick={step.onClick}
                className="flex w-full flex-col border-l-4 border-accent700 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-1 md:pb-0"
                aria-current="step"
              >
                <span className="mt-1 text-sm font-semibold  text-accent700">
                  {step.headline}
                </span>
                <span className="text-sm font-normal text-accent600">
                  {step.subtitle}
                </span>
              </button>
            ) : (
              <button
                disabled={i >= enabledSteps + 1}
                onClick={step.onClick}
                className="group flex w-full flex-col border-l-4 border-gray200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-1 md:pb-0"
              >
                <span className="mt-1 text-sm font-semibold text-gray700  group-hover:text-gray700">
                  {step.headline}
                </span>
                <span className="text-sm font-normal  text-gray600">
                  {step.subtitle}
                </span>
              </button>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

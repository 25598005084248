import React from "react";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
};

export default function MainButton(props: Props) {
  return (
    <button
      className="special-button-gradient mt-4 h-[46px] w-full rounded-lg border border-accent600 px-4 text-base font-semibold text-white hover:opacity-70 disabled:cursor-not-allowed disabled:opacity-50"
      {...props}
    >
      {props.text}
    </button>
  );
}

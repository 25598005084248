import React from "react";
import { Photo } from "./Photo";
import { t } from "i18next";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

type Props = {
  className?: string;
  title: string;
  description: string;
  imageId: string | undefined;
  imageFile: File | undefined;
  onFileSelected: (file: File) => void;
};

export const SettingsFileUpload: React.FC<Props> = ({
  className,
  title,
  description,
  imageId,
  imageFile,
  onFileSelected,
}) => {
  return (
    <div className="flex w-full flex-col gap-2 md:flex-row md:gap-8">
      <div className="flex w-full flex-col md:w-1/3">
        <label className="text-left text-sm font-medium text-gray700">
          {title}
        </label>
        <p className="text-left text-sm font-normal text-gray600">
          {description}
        </p>
      </div>
      <div className="flex w-full flex-row gap-8 md:max-w-lg">
        {(imageId || imageFile) && (
          <div className="flex h-32 w-32 flex-shrink-0">
            <Photo id={imageId} file={imageFile} />
          </div>
        )}
        <div className="relative flex h-32 flex-1 flex-shrink-0 flex-col items-center justify-center rounded-xl border border-gray200 px-8">
          <div className="flex h-12 w-12 -translate-x-1 -translate-y-1 items-center justify-center rounded-full border-[8px] border-gray50 bg-gray100">
            <div className="button-heroicon-stroke h-5 w-5 rounded-full text-gray600">
              <CloudArrowUpIcon className="button-heroicon-stroke" />
            </div>
          </div>
          <p className="mt-3 text-sm font-normal text-gray600">
            <span className="font-semibold text-accent700">
              {t("product.clickToUpload")}
            </span>{" "}
            {t("product.orDragAndDrop")}
          </p>
          <p className="mt-1 text-xs font-normal text-gray600">
            {t("product.maxImageSize")}
          </p>

          <input
            type="file"
            className={
              "absolute inset-0 h-full w-full cursor-pointer opacity-0"
            }
            accept="image/*"
            onChange={(e) => {
              if (e.target.files) {
                onFileSelected(e.target.files[0]);
              }
            }}
            onDrop={(e) => {
              e.preventDefault();
              if (e.dataTransfer.files) {
                onFileSelected(e.dataTransfer.files[0]);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

import { CalendarIcon, CheckIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";
import { RewardClaim, RewardDetail } from "../../API/data-contracts";
import { shortDate } from "../../util";
import { useWeb3 } from "../../web3/Web3Context";
import { AnonymousPhoto } from "./AnonymousPhoto";
import MainButton from "./MainButton";
import Tag from "./Tag";

// Reward
type ProjectRewardCardProps = {
  tokenId: string;
  rewardClaim: RewardClaim;
  setRewardDetail: (reward: RewardDetail) => void;
  state: "active" | "claimed" | "expired" | undefined;
  connected: boolean;
  eligible: boolean;
  onClaim: () => void;
};

export const ProjectRewardCard: React.FC<ProjectRewardCardProps> = ({
  tokenId,
  rewardClaim,
  setRewardDetail,
  state,
  connected,
  eligible,
  onClaim,
}) => {
  const { connectWallet, web3CapableBrowser, nfts } = useWeb3();
  //   console.log(tokenId + " nfts " + nfts.map((nft) => nft.tokenId));

  const owner = nfts ? nfts.find((nft) => nft.tokenId == tokenId) : undefined;

  function getButton(): JSX.Element {
    if (connected && !owner) {
      return (
        <button
          className="mt-4 h-[46px] w-full cursor-not-allowed rounded-lg border border-gray200 text-base font-semibold text-gray500"
          disabled={true}
        >
          {t("projectPage.notOwner")}
        </button>
      );
    }

    switch (state) {
      case "active":
        if (!connected) {
          /* Connect Wallet Button */
          return (
            <MainButton
              onClick={connectWallet}
              disabled={!web3CapableBrowser}
              text={
                web3CapableBrowser
                  ? t("projectPage.connectToClaim")
                  : t("projectPage.useWeb3Browser")
              }
            />
          );
        }
        if (eligible) {
          /* Claim Button */
          return <MainButton onClick={onClaim} text={t("projectPage.claim")} />;
        } else {
          /* No Luck this time Button */
          return (
            <button className="mt-4 h-[46px] w-full cursor-not-allowed rounded-lg border border-gray200 px-4 text-base font-semibold text-gray500">
              {t("projectPage.notEligible")}
            </button>
          );
        }
      case "claimed":
        return (
          <button className="mt-4 flex h-[46px] w-full cursor-not-allowed flex-row items-center justify-center gap-2 rounded-lg border border-green50 bg-green50 px-4 text-base font-semibold text-green600">
            <CheckIcon className="button-heroicon-stroke h-4" />
            {t("projectPage.claimed")}
          </button>
        );
      case "expired":
        return (
          <button className="mt-4 h-[46px] w-full cursor-not-allowed rounded-lg border border-gray200 px-4 text-base font-semibold text-gray300">
            {t("projectPage.claimPeriodOver")}
          </button>
        );
      default:
        return <div>TEST</div>;
    }
  }

  return (
    <div className="flex h-fit flex-col overflow-hidden rounded-xl border border-gray200 bg-white">
      {/* Image */}
      <div className="relative h-32 w-full bg-gray200">
        <AnonymousPhoto id={rewardClaim.reward.imageId} />

        {eligible && (
          <div className="absolute top-4 left-4">
            <Tag text={t("projectPage.eligible")} style="green" wide />
          </div>
        )}
      </div>
      {/* Content */}
      <div className="flex h-full w-full flex-col justify-between px-4 py-4">
        <div className="flex w-full flex-col">
          {/* Reward Indicator */}
          <p className="w-full text-left text-sm font-semibold text-accent700">
            {t("projectPage.reward")}
          </p>
          {/* Title */}
          <p className="mt-1 h-14 w-full text-left text-lg font-medium text-gray900 line-clamp-2">
            {rewardClaim.reward.title}
          </p>
          {/* Description */}
          <button
            className="mt-2 text-left text-sm font-bold text-gray-400"
            onClick={() => setRewardDetail(rewardClaim.reward)}
          >
            {t("projectPage.readMore")}
          </button>
          {/* Claim Window */}
          {rewardClaim.reward.claimWindow?.end ? (
            <div className="mt-4 flex w-full flex-row items-center justify-start gap-2 text-base font-medium text-gray600">
              <CalendarIcon className="h-4 text-gray400" />
              <p>
                {t("projectPage.window", {
                  date: shortDate(
                    new Date(rewardClaim.reward.claimWindow?.end ?? "")
                  ),
                })}
              </p>
            </div>
          ) : (
            <div className="mt-4 flex h-6 w-full" />
          )}
        </div>

        {/* Button */}
        {getButton()}
      </div>
    </div>
  );
};

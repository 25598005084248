import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../API/AuthContext";
import { ProductDetail } from "../API/data-contracts";
import { ConfirmDialog } from "../Components/Dialogs/ConfirmDialog";
import NewProductDialog from "../Components/Dialogs/NewProductDialog";
import DetailHeader from "../Components/Label/DetailHeader";
import HeaderLabel from "../Components/Label/HeaderLabel";
import Button from "../Components/UI/Button";
import { Photo } from "../Components/UI/Photo";
import Separator from "../Components/UI/Separator";
import { TableLoadingIndicator } from "../Components/UI/TableLoadingIndicator";
import Tag from "../Components/UI/Tag";
import { useError } from "../Error/ErrorContext";
import { delay } from "../util";

type Props = {};

export default function Products({}: Props) {
  const { t } = useTranslation();
  const { api } = useAuth();
  const { setError } = useError();
  const [products, setProducts] = useState<ProductDetail[]>();
  const [showNewProduct, setShowNewProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [updateNewProductKey, setUpdateNewProductKey] = useState(0);

  const fetchProducts = async () => {
    setIsLoading(true);
    const response = await api.getUserProducts({ secure: true });
    if (response.ok) {
      setProducts(
        response.data.sort((a, b) => {
          return a.title.localeCompare(b.title);
        }) ?? []
      );
    } else if (response.error) {
      setError(response.error);
    }
    setIsLoading(false);
  };

  // fetch products on mount
  useEffect(() => {
    fetchProducts();
  }, []);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteProductData, setDeleteProductData] = useState<
    ProductDetail | undefined
  >();
  function deleteProduct(product: ProductDetail) {
    setDeleteProductData(product);
  }
  useEffect(() => {
    if (deleteProductData) {
      setShowConfirmDelete(true);
    }
  }, [deleteProductData]);

  const [editProductData, setEditProductData] = useState<
    ProductDetail | undefined
  >();
  function editProduct(product: ProductDetail) {
    setEditProductData(product);
  }
  useEffect(() => {
    if (editProductData) {
      setShowNewProduct(true);
    }
  }, [editProductData]);

  return (
    <div className="flex w-full flex-col items-start">
      <div className="flex w-full justify-between">
        <HeaderLabel text={t("menu.products")} />
        <div className="flex gap-2">
          <Button
            icon={<PlusIcon />}
            text={t("buttons.newProduct")}
            onClick={() => setShowNewProduct(true)}
          />
          <NewProductDialog
            isOpen={showNewProduct}
            close={(reload) => {
              if (reload) {
                fetchProducts();
              }
              setShowNewProduct(false);
              delay(200).then(() => {
                setUpdateNewProductKey((prev) => prev + 1);
                setEditProductData(undefined);
              });
            }}
            initialData={editProductData}
            key={
              (editProductData?.id ?? "") +
              updateNewProductKey +
              "_ProductDialog_Key"
            }
          />
          <ConfirmDialog
            isOpen={showConfirmDelete}
            close={() => {
              setShowConfirmDelete(false);
              delay(200).then(() => setDeleteProductData(undefined));
            }}
            onAction={() => {
              if (deleteProductData) {
                api
                  .deleteUserProduct(deleteProductData.id, { secure: true })
                  .then(() => {
                    setShowConfirmDelete(false);
                    fetchProducts();
                  });
              }
            }}
            title={t("dialog.delete.title")}
            body={t("dialog.delete.body")}
            actionText={t("dialog.delete.actionText")}
          />
        </div>
      </div>
      <DetailHeader
        header={t("products.all")}
        subHeader={t("products.subHeadline")}
        headerTag={
          products?.length
            ? products?.length + " " + t("products.products")
            : undefined
        }
      />

      <div className="mt-4 w-full break-all text-left">
        <table className="w-full">
          <tr className="flex items-center border-t border-t-secondaryBackground bg-gray50 px-6 py-3 text-xs text-secondaryText">
            <th className="flex-[3]">{t("product.table.product")}</th>
            <th className="flex-[3]">{t("product.table.usedIn")}</th>
            <th className="flex flex-[1] items-center justify-center">
              {t("product.table.claims")}
            </th>
            <th className="flex-[1]"></th>
          </tr>

          {isLoading && <TableLoadingIndicator rows={5} />}

          {!isLoading &&
            products?.map((product, i) => {
              return (
                <>
                  <Separator />
                  <tr key={i} className="flex px-6 py-6">
                    <td className="flex flex-[3] flex-row items-center justify-start gap-3">
                      <div className="h-10 w-10">
                        <Photo id={product.imageIds?.[0]} />
                      </div>
                      <div className="flex flex-col">
                        <h5 className="text-sm font-medium text-gray900">
                          {product.title}
                        </h5>
                        <p className="text-sm font-normal text-gray600">
                          {product.description}
                        </p>
                      </div>
                    </td>
                    <td className="my-auto flex h-fit flex-[3] flex-row items-center justify-start gap-1">
                      {product.rewards.map(
                        (reward, i) =>
                          i < 2 && (
                            <Tag text={reward.title} key={i} style="accent" />
                          )
                      )}
                      {product.rewards.length > 2 && (
                        <Tag
                          text={"+" + (product.rewards.length - 2)}
                          key={i}
                          style="gray"
                          className="flex flex-shrink-0"
                        />
                      )}
                    </td>
                    <td className="flex flex-1 items-center justify-center">
                      <p className="text-sm font-normal text-gray600">
                        {product.claimCount}
                      </p>
                    </td>
                    <td className="flex flex-1 items-center justify-end">
                      <div className="flex gap-2 text-sm">
                        <button
                          className="font-semibold text-gray600 hover:opacity-70"
                          onClick={() => deleteProduct(product)}
                        >
                          {t("buttons.delete")}
                        </button>
                        <button
                          className="font-semibold text-accent700 hover:opacity-70"
                          onClick={() => editProduct(product)}
                        >
                          {t("buttons.edit")}
                        </button>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
        </table>
      </div>
    </div>
  );
}

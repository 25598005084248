import {
  ClockIcon,
  ChevronRightIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../API/AuthContext";
import { ClaimDetail } from "../API/data-contracts";
import HeaderLabel from "../Components/Label/HeaderLabel";
import Button, { ButtonStyle } from "../Components/UI/Button";
import ClaimDetailSlide from "../Components/UI/ClaimDetailSlide";
import { OptionBar } from "../Components/UI/OptionBar";
import Separator from "../Components/UI/Separator";
import { TableFooter } from "../Components/UI/TableFooter";
import { TableLoadingIndicator } from "../Components/UI/TableLoadingIndicator";
import Tag from "../Components/UI/Tag";
import { useError } from "../Error/ErrorContext";
import { delay } from "../util";

type Props = {};

export default function Claims({}: Props) {
  const { t } = useTranslation();
  const { api } = useAuth();
  const { setError } = useError();
  const [claims, setClaims] = useState<ClaimDetail[]>();
  const [claimDetail, setClaimDetail] = useState<ClaimDetail>();
  const [claimDetailOpen, setClaimDetailOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState<number>(0); //0: Claimed, 1: Completed, 2: Cancelled

  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const fetchClaims = async () => {
    //TODO: Add Filtering
    setIsLoading(true);
    const response = await api.getUserClaims({
      page: page,
      size: pageSize,
      status:
        activeFilter === 0
          ? "Claimed"
          : activeFilter === 1
          ? "Completed"
          : "Cancelled",
    });
    if (response.ok) {
      setClaims(response.data.content ?? []);
      setTotalItems(response.data.totalElements ?? 0);
    } else if (response.error) {
      setError(response.error);
    }

    setIsLoading(false);
  };

  // fetch products on mount
  useEffect(() => {
    fetchClaims();
  }, [page, activeFilter]);

  const downloadCSV = async () => {
    try {
      const response = await api.getClaimsCsv({}, { format: "text" });
      // Download file, supported by html5 browsers
      var a = document.createElement("a");
      a.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent("" + response.data)
      );
      a.setAttribute("download", "claims.csv");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="flex w-full flex-col items-start">
      <div className="mb-8 flex w-full justify-between">
        <HeaderLabel text={t("menu.claims")} />
        <div className="flex gap-2">
          <Button
            icon={<CloudArrowDownIcon className="button-heroicon-stroke" />}
            style={ButtonStyle.GRAY}
            text={t("buttons.exportCsv")}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await downloadCSV();
              setLoading(false);
            }}
          />
        </div>
      </div>
      <ClaimDetailSlide
        claimDetail={claimDetail}
        isOpen={claimDetailOpen}
        close={() => {
          delay(500).then(() => setClaimDetail(undefined));
          setClaimDetailOpen(false);
        }}
        onUpdate={() => {
          setClaimDetailOpen(false);
          fetchClaims();
        }}
      />

      <OptionBar
        options={[
          {
            title: t("claim.newClaims"),
            additionalInfo: (claims?.length ?? 0).toString(),
          },
          { title: t("claim.completedClaims") },
          { title: t("claim.cancelledClaims") },
        ]}
        onChange={(index) => setActiveFilter(index)}
        activeIndex={activeFilter}
      />
      <div className="mt-4 w-full break-all text-left">
        <table className="w-full">
          <tr className="flex items-center border-t border-t-secondaryBackground bg-gray50 px-6 py-3 text-xs text-secondaryText">
            <th className="flex-[2] items-center">{t("claim.table.name")}</th>
            <th className="flex-[3] items-center">{t("claim.table.email")}</th>
            <th className="flex-[3] items-center">
              {t("claim.table.address")}
            </th>
            <th className="flex-[2] items-center">{t("claim.table.reward")}</th>
            <th className="flex flex-[2] items-center justify-center">
              {t("claim.table.status")}
            </th>
            <th className="w-5">
              {
                //Detail Button
              }
            </th>
          </tr>

          {isLoading && <TableLoadingIndicator rows={5} />}

          {!isLoading &&
            claims?.map((claim, i) => (
              <button
                className="w-full text-left hover:opacity-70"
                onClick={() => {
                  setClaimDetail(claim);
                  setClaimDetailOpen(true);
                }}
              >
                <Separator />
                <tr key={i} className="flex px-6 py-6">
                  <td className="flex flex-[2] items-center justify-start">
                    <h5 className="text-sm font-medium text-sidebarBackground">
                      {claim.contactInformation?.firstName +
                        " " +
                        claim.contactInformation?.lastName}
                    </h5>
                  </td>
                  <td className="flex flex-[3] items-center justify-start">
                    <p className="text-sm font-normal text-secondaryText">
                      {claim.contactInformation?.email}
                    </p>
                  </td>
                  <td className="flex flex-[3] items-center justify-start">
                    <p className="text-sm font-normal text-secondaryText">{`${claim.contactInformation?.address?.string}`}</p>
                  </td>
                  <td className="flex flex-[2] items-center justify-start">
                    <p className="text-sm font-normal text-secondaryText">{`${claim.reward.title}`}</p>
                  </td>
                  <td className="flex flex-[2] items-center justify-center">
                    <Tag
                      text={`${claim.status}`}
                      style="gray"
                      icon={<ClockIcon />}
                    />
                  </td>
                  <td className="flex w-5 flex-row items-center justify-center">
                    <ChevronRightIcon className="h-5 w-5 text-gray400" />
                  </td>
                </tr>
              </button>
            ))}
        </table>
        <Separator />

        <TableFooter
          onPageChange={(page) => setPage(page)}
          page={page}
          pages={totalItems / pageSize}
        />
      </div>
    </div>
  );
}

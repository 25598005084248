import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import Button, { ButtonStyle } from "../Components/UI/Button";
import { showError } from "../util";

export default function Login() {
  const [searchParams] = useSearchParams();

  const loginError = searchParams.get("error");
  const { authorize, authorizeCalled } = useAuth();

  const [reset, setReset] = useState(false);

  const xsrfCookie = Cookies.get("XSRF-TOKEN");
  const authorizeInitiated = authorizeCalled();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);

  const { api } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    // call authorize when authorize was not called or when the page was accessed directly (not via redirect)
    if (!authorizeInitiated || !document.referrer || !xsrfCookie) {
      authorize();
    }

    console.log("Page accessed via referrer: " + document.referrer);
  }, []);

  const { t } = useTranslation();

  const url = process.env.REACT_APP_API_URL + "/login";

  return (
    <div className="flex h-screen min-h-screen w-full flex-col items-center justify-center bg-white">
      {/* Logo */}
      <img src="/images/login-logo.svg" className="h-8 w-full object-contain" />

      {reset ? (
        <>
          <div className="mt-8 text-left">
            {/* Tile */}
            <h3 className="text-center text-3xl font-semibold text-gray900">
              {t("login.reset.headling")}
            </h3>
            <p className="mt-3 text-center text-base font-normal text-gray600">
              {t("login.reset.subheadline")}
            </p>
          </div>
          {/* Email Password Fields */}
          <div className="mt-8 w-96 px-4 text-left">
            <input
              name="username"
              placeholder={t("login.enterYourEmail")}
              className="mt-1 w-full rounded-lg border border-gray300 bg-white px-3.5 py-2.5 text-base font-normal text-gray-900 focus:z-10 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-accent600"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
            {/* Login Button */}
            <input
              type="submit"
              value={t("login.reset.button")}
              name="submit"
              className="mt-6 w-full cursor-pointer rounded-lg bg-accent600 py-2.5 text-base font-semibold text-white hover:opacity-70"
              onClick={async () => {
                setLoading(true);
                try {
                  await api.sendResetPasswordLink({
                    email: email,
                  });
                } catch (error) {
                  showError(error);
                }
                setResetSuccess(true);
                setLoading(false);
              }}
            />
            {/* Login instead */}
            <p
              className="mt-8 cursor-pointer text-center text-sm font-semibold text-accent700 hover:opacity-70"
              onClick={() => {
                setReset(false);
                setResetSuccess(false);
              }}
            >
              {t("login.reset.loginInstead")}
            </p>

            {resetSuccess && (
              <div className="mt-4 rounded-lg bg-accent50  p-2">
                <p className="text-center font-medium text-accent">
                  {t("error.reset.success")}
                </p>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="mt-8 text-left">
            {/* Tile */}
            <h3 className="text-center text-3xl font-semibold text-gray900">
              {t("login.headline")}
            </h3>
            <p className="mt-3 text-center text-base font-normal text-gray600">
              {t("login.subheadline")}
            </p>
          </div>
          {/* Email Password Fields */}
          <div className="mt-8 w-96 px-4 text-left">
            <form action={url} method="POST" className="flex flex-col">
              <input type="hidden" name="_csrf" value={xsrfCookie} />

              <input
                name="username"
                placeholder={t("login.enterYourEmail")}
                className="mt-1 rounded-t-lg border border-gray300 bg-white px-3.5 py-2.5 text-base font-normal text-gray-900 focus:z-10 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-accent600"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <input
                name="password"
                type="password"
                placeholder="••••••••"
                className="rounded-b-lg border border-gray300 bg-white px-3.5 py-2.5 text-base font-normal text-gray-900 focus:z-10 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-accent600"
              />
              {/* Login Button */}
              <input
                type="submit"
                value={t("login.loginButton")}
                name="submit"
                className="mt-6 w-full cursor-pointer rounded-lg bg-accent600 py-2.5 text-base font-semibold text-white hover:opacity-70"
              />

              {/* Dont have account - get in touch */}
              <p className="mt-8 text-center text-sm font-normal text-gray600">
                {t("login.dontHaveAccount")}{" "}
                <span
                  className="cursor-pointer font-semibold text-accent700 hover:opacity-70"
                  onClick={() => window.open("mailto:hello@nftclaimer.xyz")}
                >
                  {t("login.getInTouch")}
                </span>
              </p>

              {/* Forgot Password */}
              <p
                className="mt-3 cursor-pointer text-center text-sm font-semibold text-accent700 hover:opacity-70"
                onClick={() => setReset(true)}
              >
                {t("login.forgotPassword")}
              </p>

              {loginError && (
                <div className="mt-4 rounded bg-white p-2">
                  <p className="text-center text-accent">
                    {t("error.invalidCredentials")}
                  </p>
                </div>
              )}
            </form>
          </div>{" "}
        </>
      )}
    </div>
  );
}

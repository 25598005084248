import { toast } from "react-toastify";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const throwEmptyContext = () => {
  throw new Error("EMPTY_CONTEXT");
};

export function convertIpfs(url) {
  return url.replace("ipfs://", "https://ahoikapptn.mypinata.cloud/ipfs/");
}

export const businessImageUrl = (businessId, i) => {
  return `${process.env.REACT_APP_API_URL}/api/business/images/${businessId}/${i}`;
};

export const shortenAddress = (address) => {
  return `${address.substring(0, 5)}...${address.substring(
    address.length - 4
  )}`;
};

var lastErrorId;
export function showError(text) {
  if (lastErrorId) {
    toast.dismiss(lastErrorId);
  }

  lastErrorId = toast.error(text, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "bg-white font-semibold text-sm m-1 m-1 rounded-card text-text",
  });
}

export function shortDate(date) {
  return date.toLocaleDateString("de-DE", {
    month: "short",
    day: "numeric",
    year: "numeric"
  });
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function array(from, to) {
  return [...Array(to - from + 1).keys()].map((i) => i + from);
}

export function dateToYYYYmmdd(date) {
  return date.toISOString().split("T")[0];
}

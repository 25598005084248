import React from "react";
import { classNames } from "../../util";

type Props = {
  text: string;
  style?: "accent" | "gray" | "green" | "blue";
  className?: string;
  icon?: JSX.Element;
  small?: boolean;
  wide?: boolean;
};

export default function Tag({
  text,
  style = "accent",
  className,
  icon,
  small,
  wide,
}: Props) {
  let styleClasses;
  switch (style) {
    case "accent":
      styleClasses = "bg-accent50 text-accent700";
      break;
    case "gray":
      styleClasses = "bg-secondaryBackground text-primaryText";
      break;
    case "green":
      styleClasses = "bg-green50 text-green700";
      break;
    case "blue":
      styleClasses = "bg-blueLight50 text-blueLight700";
      break;
  }

  return (
    <div
      className={classNames(
        styleClasses,
        className,
        "flex w-fit items-center justify-center gap-1 rounded-full text-xs font-medium",
        wide ? "px-3 py-1" : small ? "px-2 py-0.5" : "px-2 py-1"
      )}
    >
      {icon && <div className="flex h-3 w-3 flex-shrink-0">{icon}</div>}
      <p className="line-clamp-1">{text}</p>
    </div>
  );
}

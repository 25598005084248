import {
  CircleStackIcon,
  ClockIcon,
  PlusIcon,
  SparklesIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { EyeSlashIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { RewardDetail } from "../API/data-contracts";
import { ConfirmDialog } from "../Components/Dialogs/ConfirmDialog";
import HeaderLabel from "../Components/Label/HeaderLabel";
import Button, { ButtonStyle } from "../Components/UI/Button";
import MoreMenu from "../Components/UI/MoreMenu";
import { OptionBar } from "../Components/UI/OptionBar";
import Separator from "../Components/UI/Separator";
import { TableLoadingIndicator } from "../Components/UI/TableLoadingIndicator";
import Tag from "../Components/UI/Tag";
import { shortDate } from "../util";

export function mapFilterText(filter: string | undefined): {
  text: string | undefined;
  icon: JSX.Element | undefined;
  color: "gray" | "green" | "blue" | "accent" | undefined;
} {
  switch (filter) {
    case "Active":
      return {
        text: t("rewards.active"),
        icon: <SparklesIcon />,
        color: "green",
      };
    case "Upcoming":
      return {
        text: t("rewards.upcoming"),
        icon: <ClockIcon />,
        color: "blue",
      };
    case "Past":
      return { text: t("rewards.past"), icon: undefined, color: "gray" };
    default:
      return { text: "", icon: undefined, color: "gray" };
  }
}

type Props = {};

export default function Rewards({}: Props) {
  const { api } = useAuth();
  const [rewards, setRewards] = useState<RewardDetail[]>([]);

  const [rewardToDelete, setRewardToDelete] = useState<
    RewardDetail | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [activeFilter, setActiveFilter] = useState<number>(0);
  function mapFilterToStatus() {
    switch (activeFilter) {
      case 0:
        return undefined;
      case 1:
        return "Active";
      case 2:
        return "Upcoming";
      case 3:
        return "Past";
    }
  }

  const loadRewards = async () => {
    setIsLoading(true);
    const response = await api.getUserRewards({ status: mapFilterToStatus() });
    if (response.ok) {
      setRewards(
        response.data.sort((a, b) => {
          // sort by createdDate
          return (
            new Date(b.createdDate ?? 0).getTime() -
            new Date(a.createdDate ?? 0).getTime()
          );
        }) ?? []
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadRewards();
  }, [activeFilter]);

  return (
    <div className="flex w-full flex-col items-start">
      <div className="mb-8 flex w-full justify-between">
        <HeaderLabel text={t("menu.rewards")} />
        <div className="flex gap-2">
          <Link to="/rewards/new">
            <Button
              icon={<PlusIcon className="button-heroicon-stroke" />}
              style={ButtonStyle.FILLED}
              text={t("buttons.newReward")}
            />
          </Link>
        </div>
      </div>
      <OptionBar
        options={[
          {
            title: t("rewards.allRewards"),
            additionalInfo: rewards.length.toString(),
          },
          { title: t("rewards.activeRewards") },
          { title: t("rewards.upcomingRewards") },
          { title: t("rewards.pastRewards") },
        ]}
        onChange={(index) => setActiveFilter(index)}
        activeIndex={activeFilter}
      />
      <div className="mt-4 w-full break-all text-left">
        <table className="w-full">
          <tr className="flex items-center gap-4 border-t border-t-secondaryBackground bg-gray50 px-6 py-3 text-xs text-secondaryText">
            <th className="flex-[2] md:flex-[3]">{t("reward.table.title")}</th>
            <th className="flex-[1]">{t("reward.table.claimPeriod")}</th>
            <th className="flex flex-[1] items-center justify-center">
              {t("reward.table.status")}
            </th>
            <th className="hidden flex-[1] items-center justify-center md:flex">
              {t("reward.table.claims")}
            </th>
            <th className="flex flex-[2] items-center justify-center">
              {t("reward.table.type")}
            </th>
            <th className="flex flex-[2] items-center justify-center">
              {t("reward.table.products")}
            </th>
            <th className="flex flex-[1]"></th>
          </tr>

          {isLoading && <TableLoadingIndicator rows={5} />}

          {!isLoading &&
            rewards?.map((reward, i) => {
              return (
                <>
                  <Separator key={`separator-${i}`} />
                  <tr key={i} className="z-30 flex gap-4 px-6 py-6">
                    <td className="flex flex-[2] items-center justify-start md:flex-[3]">
                      <h5 className="text-sm font-medium text-sidebarBackground">
                        {reward.title}
                      </h5>
                    </td>
                    <td className="flex flex-[1] items-center justify-start">
                      <p className="text-sm font-normal text-secondaryText">
                        {reward.claimWindow?.start &&
                          shortDate(new Date(reward.claimWindow?.start))}
                        {reward.claimWindow?.start &&
                          reward.claimWindow.end &&
                          " - "}
                        {reward.claimWindow?.end &&
                          shortDate(new Date(reward.claimWindow?.end))}
                      </p>
                    </td>
                    <td className="flex flex-[1] items-center justify-center">
                      {reward.enabled ? (
                        <Tag
                          text={mapFilterText(reward.status)?.text ?? ""}
                          style={mapFilterText(reward.status)?.color ?? "gray"}
                          icon={mapFilterText(reward.status)?.icon}
                        />
                      ) : (
                        <Tag
                          text={t("reward.status.disabled")}
                          style="gray"
                          icon={<EyeSlashIcon />}
                        />
                      )}
                    </td>
                    <td className="hidden flex-[1] items-center justify-center md:flex">
                      <div>
                        <p className="text-sm font-normal text-secondaryText">{`${
                          reward.count ?? "-"
                        }`}</p>
                      </div>
                    </td>
                    <td className="flex flex-[2] items-center justify-center">
                      <Tag
                        text={t("reward.type." + reward.type)}
                        style="gray"
                        icon={
                          reward.type === "LuckyWinner" ? (
                            <SparklesIcon />
                          ) : reward.type === "Everyone" ? (
                            <UserGroupIcon />
                          ) : (
                            <CircleStackIcon />
                          )
                        }
                      />
                    </td>
                    <td className="grid flex-[2] grid-cols-1 items-center gap-2 lg:grid-cols-2">
                      {reward.products &&
                        reward.products.length > 0 &&
                        reward.products.map((product: any) => (
                          <Tag
                            className="col-span-1"
                            key={product.title}
                            text={`${product.title}`}
                            style="accent"
                          />
                        ))}
                    </td>
                    <td className="flex-[1] cursor-pointer items-center justify-center hover:opacity-70">
                      <MoreMenu
                        elements={[
                          {
                            function: () => {
                              setRewardToDelete(reward);
                            },
                            text: t("buttons.delete"),
                          },
                          {
                            function: () => {
                              navigate("/rewards/new", {
                                state: { reward },
                              });
                            },
                            text: t("buttons.edit"),
                          },
                          {
                            function: async () => {
                              try {
                                const response = await api.createUserReward({
                                  title: `${reward.title} copy`,
                                  claimWindow: reward.claimWindow,
                                  enabled: false,
                                  tokenIds: reward.tokenIds,
                                  restrictions: reward.restrictions,
                                  count: reward.count,
                                  description: reward.description,
                                  productIds:
                                    reward.products?.map((p) => p.id) ?? [],
                                });
                                loadRewards();
                              } catch (error) {
                                console.error(error);
                              }
                            },
                            text: t("buttons.duplicate"),
                          },
                          {
                            function: async () => {
                              const enabled = !reward.enabled;
                              try {
                                const response = await api.setRewardEnabled(reward.id, enabled);
                                reward.enabled = enabled;
                                setRewards([...rewards]);
                              } catch (error) {
                                loadRewards();
                              }
                            },
                            text: reward.enabled
                              ? t("buttons.disable")
                              : t("buttons.enable"),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
        </table>
      </div>

      <ConfirmDialog
        isOpen={rewardToDelete !== undefined}
        title={t("rewards.deleteReward")}
        body={t("rewards.deleteRewardBody")}
        close={() => setRewardToDelete(undefined)}
        actionText={t("buttons.delete")}
        onAction={async () => {
          if (rewardToDelete) {
            try {
              const response = await api.deleteUserReward(rewardToDelete.id);
              const newRewards = [...rewards].filter(
                (r) => r.id !== rewardToDelete.id
              );
              setRewards(newRewards);
            } catch (error) {
              loadRewards();
            }
          }
          setRewardToDelete(undefined);
        }}
      />
    </div>
  );
}

import React from "react";
import { classNames } from "../../util";

type Props = {
  className?: string;
  title: string;
  description: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  prefix?: string;
  type?: string;
  placeholder?: string;
};

export const SettingsTextInput: React.FC<Props> = ({
  className,
  title,
  description,
  value,
  onChange,
  isDisabled,
  prefix,
  type = "text",
  placeholder,
  children,
}) => {
  return (
    <div className="flex w-full flex-col gap-2 md:flex-row md:gap-8">
      <div className="flex w-full flex-col md:w-1/3">
        <label className="text-left text-sm font-medium text-gray700">
          {title}
        </label>
        <p className="text-left text-sm font-normal text-gray600">
          {description}
        </p>
      </div>
      <div className="flex w-full flex-col items-start gap-4 md:max-w-lg">
        <div className="flex h-fit w-full flex-row">
          {type === "textarea" ? (
            <textarea
              className={classNames(
                "flex h-24 w-full items-start justify-start rounded-md border-gray300 text-base font-normal shadow-sm focus:border-accent focus:ring-accent sm:text-sm",
                isDisabled
                  ? "pointer-events-none bg-gray50 text-gray500"
                  : "text-gray900"
              )}
              value={value}
              onChange={(e) => !isDisabled && onChange(e.target.value)}
              placeholder={placeholder}
            />
          ) : (
            <div className="flex w-full flex-row">
              {prefix && (
                <div className="flex h-full items-center justify-center rounded-l-md border border-r-0 border-gray300 px-3 text-base font-normal text-gray600 sm:text-sm">
                  {prefix}
                </div>
              )}
              <input
                type={type}
                className={classNames(
                  "flex w-full items-start justify-start border-gray300 text-base font-normal shadow-sm focus:border-accent focus:ring-accent sm:text-sm",
                  isDisabled
                    ? "pointer-events-none bg-gray50 text-gray500"
                    : "text-gray900",
                  prefix ? "rounded-r-md" : "rounded-md"
                )}
                value={value}
                onChange={(e) => !isDisabled && onChange(e.target.value)}
                placeholder={placeholder}
              />
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

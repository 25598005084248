import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { array, classNames } from "../../util";

type Props = {
  className?: string;
  onPageChange: (page: number) => void;
  page: number;
  pages: number;
};

export const TableFooter: React.FC<Props> = ({
  className,
  onPageChange,
  page,
  pages,
}) => {
  const totalPages = Math.ceil(pages);

  return (
    <div className="flex w-full flex-row justify-between py-6">
      <div
        className={classNames(
          "flex flex-row items-center gap-3 text-sm font-semibold",
          page > 0
            ? "cursor-pointer text-gray600"
            : "cursor-not-allowed text-gray300"
        )}
        onClick={() => {
          if (page > 0) {
            onPageChange(page - 1);
          }
        }}
      >
        <ArrowLeftIcon className="h-3 w-3" />
        Previous
      </div>
      <div className="flex flex-row items-center text-sm font-medium text-gray-600">
        {/*CASE 1 :  pages <= 8   |   1 2 3 4 5 6 7 */}
        {totalPages <= 8 &&
          Array.from(Array(totalPages).keys()).map((_, i) => (
            <PageItem
              active={page === i}
              page={i}
              onClick={() => onPageChange(i)}
            />
          ))}

        {/* CASE 2 : pages > 8   |    1 2 3 [4] 5 ... 7 8   */}
        {totalPages > 8 && page < 4 && (
          <>
            {array(0, 4).map((i) => (
              <PageItem
                active={page === i}
                page={i}
                onClick={() => onPageChange(i)}
              />
            ))}
            <PageSeparator />
            {array(totalPages - 2, totalPages - 1).map((i) => (
              <PageItem
                active={page === i}
                page={i}
                onClick={() => onPageChange(i)}
              />
            ))}
          </>
        )}

        {/* CASE 3 : pages > 8   |    1 2 ... 4 [5] 6 ... 7 8 */}
        {totalPages > 8 && page >= 4 && page < totalPages - 4 && (
          <>
            {array(0, 1).map((i) => (
              <PageItem
                active={page === i}
                page={i}
                onClick={() => onPageChange(i)}
              />
            ))}
            <PageSeparator />
            {array(page - 1, page + 1).map((i) => (
              <PageItem
                active={page === i}
                page={i}
                onClick={() => onPageChange(i)}
              />
            ))}
            <PageSeparator />
            {array(totalPages - 2, totalPages - 1).map((i) => (
              <PageItem
                active={page === i}
                page={i}
                onClick={() => onPageChange(i)}
              />
            ))}
          </>
        )}

        {/* CASE 4 : pages > 8   |    1 2 ... 5 [6] 7 8 */}
        {totalPages > 8 && page >= totalPages - 4 && (
          <>
            {array(0, 1).map((i) => (
              <PageItem
                active={page === i}
                page={i}
                onClick={() => onPageChange(i)}
              />
            ))}
            <PageSeparator />
            {array(totalPages - 5, totalPages - 1).map((i) => (
              <PageItem
                active={page === i}
                page={i}
                onClick={() => onPageChange(i)}
              />
            ))}
          </>
        )}
      </div>
      <div
        className={classNames(
          "flex flex-row items-center gap-2 text-sm font-semibold",
          page < totalPages - 1
            ? "cursor-pointer text-gray600"
            : "cursor-not-allowed text-gray300"
        )}
        onClick={() => {
          if (page < totalPages - 1) {
            onPageChange(page + 1);
          }
        }}
      >
        Next
        <ArrowRightIcon className="h-3 w-3" />
      </div>
    </div>
  );
};

type PageItemProps = {
  className?: string;
  page: number;
  onClick: () => void;
  active: boolean;
};

const PageItem: React.FC<PageItemProps> = ({
  className,
  onClick,
  active,
  page,
}) => {
  return (
    <div
      className={classNames(
        "flex h-10 w-10 items-center justify-center transition-all",
        active
          ? "rounded-lg bg-gray50"
          : "cursor-pointer bg-transparent hover:opacity-70"
      )}
      onClick={onClick}
    >
      {page + 1}
    </div>
  );
};

const PageSeparator: React.FC = () => {
  return <p className="flex h-10 w-10 items-center justify-center">...</p>;
};

import { t } from "i18next";
import React from "react";
import MainButton from "../Components/UI/MainButton";
import { shortenAddress } from "../util";
import { useWeb3 } from "./Web3Context";

type Props = {};

export default function ConnectWalletButton({}: Props) {
  const { connectWallet, setContractAddress, account, web3CapableBrowser } =
    useWeb3();

  return (
    <MainButton
      text={account ? shortenAddress(account) : t("project.connectWallet")}
      onClick={connectWallet}
      disabled={!web3CapableBrowser}
      title={!web3CapableBrowser ? t("project.noWeb3") : undefined}
    />
  );
}

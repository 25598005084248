import { t } from "i18next";
import { useEffect, useState } from "react";
import { useUser } from "../../API/UserContext";
import { Restriction } from "../../API/data-contracts";
import Button, { ButtonStyle } from "../UI/Button";
import DropdownSelector from "../UI/DropdownSelector";
import Modal from "../../GeneralComponents/Modal";
import MultiSelector from "../UI/MultiSelector";
import Separator from "../UI/Separator";

type Props = {
  isOpen: boolean;
  close: (restriction?: Restriction) => void;
};

export default function SelectTraitsDialog({ isOpen, close }: Props) {
  const { user } = useUser();

  const [selectedTrait, setSelectedTrait] = useState<number>();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setSelectedValues([]);
  }, [selectedTrait]);

  const errorStyle =
    "border-red-600 text-red-600 focus:border-red-600 focus:ring-red-600";
  const normalStyle = "border-gray-300 focus:border-accent focus:ring-accent";

  return (
    <Modal
      title={t("buttons.addTrait")}
      subTitle={t("rewards.newReward.whichTraisCanClaimSubHeadline")}
      isOpen={isOpen}
      closeModal={() => close(undefined)}
    >
      <>
        {user && user.metaDataAttributes && (
          <div className="flex min-h-[40vh] flex-col p-6">
            <DropdownSelector
              label={t("traits.attributeHeader")}
              selected={selectedTrait}
              setSelected={setSelectedTrait}
              options={[
                {
                  traitType: "",
                },
                ...user.metaDataAttributes,
              ].map((attributes) => attributes.traitType)}
              defaultText={t("traits.selectAttribute")}
            />
            {selectedTrait !== 0 && selectedTrait && (
              <MultiSelector
                label={t("traits.valuesHeader")}
                options={user.metaDataAttributes[selectedTrait - 1].values}
                selected={selectedValues}
                setSelected={setSelectedValues}
                className="mt-8"
              />
            )}
          </div>
        )}
        <Separator />
        <div className="flex justify-end gap-2 p-4">
          <Button
            text={t("buttons.cancel")}
            style={ButtonStyle.OUTLINED}
            onClick={() => close(undefined)}
          />
          {user && user.metaDataAttributes && (
            <Button
              text={t("buttons.addTrait")}
              style={ButtonStyle.FILLED}
              disabled={
                selectedTrait == undefined || selectedValues.length == 0
              }
              onClick={() => {
                if (selectedTrait && user.metaDataAttributes) {
                  close({
                    attributeTypeKey:
                      user.metaDataAttributes[selectedTrait - 1].traitType,
                    matchingValues: selectedValues,
                  });
                  setSelectedTrait(undefined);
                  setSelectedValues([]);
                } else {
                  close(undefined);
                }
              }}
            />
          )}
        </div>
      </>
    </Modal>
  );
}

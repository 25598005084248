import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  ClaimWindow,
  Product,
  Restriction,
  RewardDetail,
} from "../API/data-contracts";
import Breadcrumbs from "../Components/UI/Breadcrumbs";
import ProgressSteps from "../Components/UI/ProgressSteps";
import AddProducts from "./rewards/AddProducts";
import AddTraits from "./rewards/AddTraits";
import PublicInfo from "./rewards/PublicInfo";

export type RewardFormData = {
  title?: string;
  description?: string;
  claimWindow?: {
    start?: string;
    end?: string;
  };
  selectedProducts?: Product[];
  restrictions?: Restriction[];
};

export default function NewRewards() {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const location = useLocation();
  const editReward = (location.state as any)?.reward as
    | RewardDetail
    | undefined;

  const [useEndDate, setUseEndDate] = useState(
    editReward?.claimWindow.end != undefined ?? false
  );

  console.log(`editReward ${JSON.stringify(editReward)}`);

  let claimWindow: ClaimWindow = {
    start: new Date().toISOString().slice(0, 16),
    end: "",
  };

  if (editReward?.claimWindow) {
    const end = editReward.claimWindow.end
      ? new Date(editReward.claimWindow.end).toISOString().slice(0, 16)
      : undefined;

    claimWindow = {
      start: new Date(editReward.claimWindow.start).toISOString().slice(0, 16),
      end,
    };
  }

  const formData = useRef<RewardFormData>({
    description: editReward?.description,
    title: editReward?.title,
    restrictions: editReward?.restrictions,
    selectedProducts: editReward?.products,
    claimWindow,
  });
  const [finishedIndex, setFinishedIndex] = useState<number>();

  const [image, setImage] = useState<File | undefined>(undefined);

  const headlines = [
    {
      title: t("rewards.newReward.publicInformation"),
      subtitle: t("rewards.newReward.publicInformationSubHeadline"),
    },
    {
      title: t("rewards.newReward.products"),
      subtitle: t("rewards.newReward.productsSubHeadline"),
    },
    {
      title: t("rewards.newReward.traits"),
      subtitle: t("rewards.newReward.traitsSubHeadline"),
    },
  ];

  let body;
  switch (step) {
    case 0:
      body = (
        <PublicInfo
          formData={formData.current}
          onSubmit={(data: any) => {
            formData.current = { ...formData.current, ...data };
            console.log(formData.current);
            setStep(1);
            if (finishedIndex === undefined) {
              setFinishedIndex(0);
            }
          }}
          startPast={
            editReward
              ? new Date(editReward.claimWindow.start) < new Date()
              : false
          }
          image={image}
          setImage={setImage}
          useEndDate={useEndDate}
          setUseEndDate={setUseEndDate}
        />
      );
      break;
    case 1:
      body = (
        <AddProducts
          formData={formData.current}
          onSubmit={(data: Product[]) => {
            formData.current = { ...formData.current, selectedProducts: data };
            console.log(formData.current);
            setStep(2);
            if (finishedIndex === 0) {
              setFinishedIndex(1);
            }
          }}
          onBack={() => {
            setStep(0);
          }}
        />
      );
      break;
    case 2:
      body = (
        <AddTraits
          formData={formData.current}
          onSubmit={(data: Restriction[]) => {
            formData.current = { ...formData.current, ...data };
            console.log(formData.current);
            if (finishedIndex === 1) {
              setFinishedIndex(2);
            }
          }}
          editingReward={editReward}
          image={image}
          onBack={() => {
            setStep(1);
          }}
        />
      );
  }

  return (
    <div className="flex w-full flex-col items-start">
      <Breadcrumbs
        breadcrumbs={[
          { name: "Rewards", href: "/rewards", current: false },
          { name: "New Reward", current: true },
        ]}
      />
      <ProgressSteps
        enabledSteps={(finishedIndex ?? -1) + 1}
        steps={headlines.map((headline, i) => {
          return {
            headline: headline.title,
            subtitle: headline.subtitle,
            onClick: () => setStep(i),
            status: i < step ? "complete" : i === step ? "current" : "upcoming",
          };
        })}
      />
      {body}
    </div>
  );
}

import {
  CheckIcon,
  SparklesIcon,
  TicketIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../API/AuthContext";
import {
  ErrorResponse,
  Restriction,
  Reward,
  RewardDetail,
} from "../../API/data-contracts";
import { HttpResponse } from "../../API/http-client";
import { ConfirmDialog } from "../../Components/Dialogs/ConfirmDialog";
import SelectTraitsDialog from "../../Components/Dialogs/SelectTraitsDialog";
import Button, { ButtonStyle } from "../../Components/UI/Button";
import EmptyAddElement from "../../Components/UI/EmptyAddElement";
import LongCheckbox from "../../Components/UI/LongCheckbox";
import MoreMenu from "../../Components/UI/MoreMenu";
import NonEmptyAddElement from "../../Components/UI/NonEmptyAddElement";
import Separator from "../../Components/UI/Separator";
import { useError } from "../../Error/ErrorContext";
import { classNames, shortDate } from "../../util";
import { RewardFormData } from "../NewRewards";

type Props = {
  editingReward?: RewardDetail;
  formData: RewardFormData;
  onSubmit: (data: Restriction[]) => void;
  onBack: () => void;
  image: File | undefined;
};

export default function AddTraits({
  editingReward,
  formData,
  onSubmit,
  onBack,
  image,
}: Props) {
  const { t } = useTranslation();
  const { setError } = useError();
  const { api } = useAuth();

  const [whoCanClaim, setWhoCanClaim] = useState<
    "Everyone" | "Specific" | "Tokens"
  >(
    editingReward?.tokenIds != undefined
      ? "Tokens"
      : editingReward?.count != undefined
      ? "Specific"
      : "Everyone"
  );
  const [showTraitsSelector, setShowTraitsSelector] = useState(false);
  const [restrictions, setRestrictions] = useState<Restriction[]>(
    formData.restrictions ?? []
  );

  const [amountWinners, setAmountWinners] = useState(editingReward?.count ?? 0);

  const [tokens, setTokens] = useState<number[]>(editingReward?.tokenIds ?? []);
  const [currentTokenText, setCurrentTokenText] = useState("");
  const [tokenInputActive, setTokenInputActive] = useState(false);
  const fakeInputRef = useRef<HTMLDivElement>(null);

  const errorStyle =
    "border-red-600 text-red-600 focus:border-red-600 focus:ring-red-600";
  const normalStyle = "border-gray-300 focus:border-accent focus:ring-accent";

  const navigate = useNavigate();
  const [confirmAddRewardDialogOpen, setConfirmAddRewardDialogOpen] =
    useState(false);

  function enterToken() {
    if (
      currentTokenText.length > 0 &&
      !tokens.includes(Number(currentTokenText))
    ) {
      setTokens((prev) => [...prev, Number(currentTokenText)]);
      setCurrentTokenText("");
    } else {
      setCurrentTokenText("");
    }
  }

  const start = new Date(
    formData.claimWindow?.start + ":00Z" ?? ""
  ).toISOString();
  const end =
    formData.claimWindow && formData.claimWindow?.end !== ""
      ? new Date(formData.claimWindow?.end + ":00Z" ?? "").toISOString()
      : undefined;

  async function addReward() {
    let result: HttpResponse<Reward, ErrorResponse>;
    if (editingReward !== undefined) {
      result = await api.updateUserReward(editingReward.id, {
        title: formData.title ?? "",
        description: formData.description ?? "",
        count: whoCanClaim === "Specific" ? amountWinners : undefined, //only use number when lucky winners is selected
        tokenIds: whoCanClaim === "Tokens" ? tokens : undefined,
        restrictions: restrictions,
        productIds: formData.selectedProducts?.map((p) => p.id) ?? [],
        claimWindow: {
          start,
          end,
        },
      });
    } else {
      //upload all data to server
      result = await api.createUserReward({
        enabled: true,
        title: formData.title ?? "",
        description: formData.description ?? "",
        count: whoCanClaim === "Specific" ? amountWinners : undefined, //only use number when lucky winners is selected
        tokenIds: whoCanClaim === "Tokens" ? tokens : undefined,
        restrictions: restrictions,
        productIds: formData.selectedProducts?.map((p) => p.id) ?? [],
        claimWindow: {
          start,
          end,
        },
      });
    }

    const rewardId = result.data.id;

    //now upload image to reward
    if (image) {
      api.setRewardImage(rewardId, { image: image }).finally(() => {
        if (result.status === 200) {
          navigate("/rewards");
        }
      });
    } else {
      if (result.status === 200) {
        navigate("/rewards");
      }
    }
  }

  const alreadyStarted = new Date(start) < new Date();

  return (
    <div className="mt-14 w-full text-left">
      <ConfirmDialog
        key={confirmAddRewardDialogOpen + JSON.stringify(formData)}
        isOpen={confirmAddRewardDialogOpen}
        title={
          t("rewards.newReward.confirmAddRewardTitle") +
          " '" +
          formData.title +
          "'"
        }
        actionText={t("buttons.confirm")}
        onAction={() => {
          addReward();
        }}
        close={() => {
          setConfirmAddRewardDialogOpen(false);
        }}
        titleCheckmark
      >
        <div className="flex-col gap-4 px-6 py-2">
          {/* Rows */}
          {/* Period */}
          <div className="flex flex-row items-center gap-2">
            <div className="h-4 w-4 rounded-[4px] border border-accent600 p-0.5">
              <CheckIcon className="button-heroicon-stroke h-full w-full text-accent600" />
            </div>
            <p className="text-sm font-medium text-gray700">
              {t("newReward.confirm.fromDate")} {shortDate(new Date(start))}
              {formData.claimWindow?.end &&
                " – " + shortDate(new Date(end ?? ""))}
            </p>
          </div>
          {/* Products */}
          <div className="flex flex-row items-center gap-2">
            <div className="h-4 w-4 rounded-[4px] border border-accent600 p-0.5">
              <CheckIcon className="button-heroicon-stroke h-full w-full text-accent600" />
            </div>
            <p className="text-sm font-medium text-gray700">
              {t("newReward.confirm.productsIncluded")}{" "}
              {formData.selectedProducts?.map((p) => p.title).join(", ")}
            </p>
          </div>
          {/* Amount */}
          {/* when tokens are selected */}
          {whoCanClaim === "Everyone" && (
            <div className="flex flex-row items-center gap-2">
              <div className="h-4 w-4 rounded-[4px] border border-accent600 p-0.5">
                <CheckIcon className="button-heroicon-stroke h-full w-full text-accent600" />
              </div>
              <p className="text-sm font-medium text-gray700">
                {t("newReward.confirm.everyone")}
              </p>
            </div>
          )}
          {/* when tokens are selected */}
          {whoCanClaim === "Tokens" && (
            <div className="flex flex-row items-center gap-2">
              <div className="h-4 w-4 rounded-[4px] border border-accent600 p-0.5">
                <CheckIcon className="button-heroicon-stroke h-full w-full text-accent600" />
              </div>
              <p className="text-sm font-medium text-gray700">
                {t("newReward.confirm.tokensWillGet", {
                  num: tokens,
                })}
              </p>
            </div>
          )}
          {/* when a total amount is selected */}
          {whoCanClaim === "Specific" && (
            <div className="flex flex-row items-center gap-2">
              <div className="h-4 w-4 rounded-[4px] border border-accent600 p-0.5">
                <CheckIcon className="button-heroicon-stroke h-full w-full text-accent600" />
              </div>
              <p className="text-sm font-medium text-gray700">
                {t("newReward.confirm.raffleWillDrawNFTs", {
                  num: amountWinners,
                })}
              </p>
            </div>
          )}

          {/* Traits */}
          {restrictions && restrictions.length > 0 && (
            <div className="flex flex-row items-center gap-2">
              <div className="h-4 w-4 rounded-[4px] border border-accent600 p-0.5">
                <CheckIcon className="button-heroicon-stroke h-full w-full text-accent600" />
              </div>
              <p className="text-sm font-medium text-gray700">
                {t("newReward.confirm.traitsNeedsToBeFulfilled", {
                  num: restrictions?.length,
                  traits: restrictions
                    ?.map((p) => p.attributeTypeKey)
                    .join(", "),
                })}
              </p>
            </div>
          )}
        </div>
      </ConfirmDialog>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-sm font-medium leading-6 text-gray700">
            {t("rewards.newReward.whoCanClaim")}
          </h3>
          <p className="text-sm font-normal text-gray600">
            {t("rewards.newReward.whoCanClaimSubHeadline")}
          </p>
        </div>
        <div className="col-span-2 flex flex-col gap-2">
          <LongCheckbox
            title={t("rewards.everyone")}
            subtitle={t("rewards.everyoneDetail")}
            selected={whoCanClaim === "Everyone"}
            onClick={() => setWhoCanClaim("Everyone")}
            icon={<UsersIcon className="h-full w-full text-accent" />}
          />
          <LongCheckbox
            title={t("rewards.selectedTokens")}
            subtitle={t("rewards.selectedTokensDetail")}
            selected={whoCanClaim === "Tokens"}
            onClick={() => setWhoCanClaim("Tokens")}
            icon={<TicketIcon className="h-full w-full text-accent" />}
          />
          <LongCheckbox
            title={t("rewards.luckyWinners")}
            subtitle={t("rewards.luckyWinnersDetail")}
            selected={whoCanClaim === "Specific"}
            onClick={() => setWhoCanClaim("Specific")}
            icon={<SparklesIcon className="h-full w-full text-accent" />}
          />
        </div>
      </div>
      {whoCanClaim === "Specific" && (
        <div className="mt-16 md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-sm font-medium leading-6 text-gray700">
              {t("rewards.newReward.howManyWinners")}
            </h3>
            <p className="text-sm font-normal text-gray600">
              {t("rewards.newReward.howManyWinnersSubHeadline", {
                amount: amountWinners,
              })}
            </p>
          </div>

          <div className="flex items-center justify-start md:col-span-2">
            <button
              className="h-10 w-10 rounded-md border text-xl"
              onClick={() => {
                if (amountWinners > 0) {
                  setAmountWinners(amountWinners - 1);
                }
              }}
            >
              -
            </button>
            <p className="mx-4 min-w-[32px] text-center font-mono text-4xl font-bold">
              {amountWinners}
            </p>
            <button
              className="h-10 w-10 rounded-md border text-xl"
              onClick={() => setAmountWinners(amountWinners + 1)}
            >
              +
            </button>
          </div>
        </div>
      )}

      {whoCanClaim === "Tokens" && (
        <div className="mt-16 md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-sm font-medium leading-6 text-gray700">
              {t("rewards.newReward.addTokensToConsidered")}
            </h3>
            <p className="text-sm font-normal text-gray600">
              {t("rewards.newReward.addTokensToConsideredSubline")}
            </p>
          </div>

          <div className="flex flex-col items-start justify-start md:col-span-2">
            {alreadyStarted && (
              <p className="text-sm text-orange-400">
                {t("warning.alreadyStarted")}
              </p>
            )}
            <div
              className={classNames(
                "min-h-32 relative flex w-full flex-row flex-wrap items-start justify-start gap-1.5 rounded-xl border p-3",
                tokenInputActive ? "border-accent600" : "border-gray300"
              )}
            >
              {tokens?.map((token) => (
                <div
                  key={token}
                  className="group z-10 flex h-fit cursor-pointer flex-row items-center gap-2 rounded-md border border-gray300 py-0.5 px-2 text-sm font-medium text-gray700 hover:opacity-70"
                  onClick={() => {
                    //remove token
                    if (!alreadyStarted) {
                      setTokens((prev) => prev.filter((t) => t !== token));
                    }
                  }}
                >
                  {token}
                  <XMarkIcon className="w-3 text-gray400 group-hover:text-accent700" />
                </div>
              ))}

              {/* fake caret for fake input */}
              {tokenInputActive && (
                <motion.div
                  className="mt-0.5 h-[22px] w-[1px] animate-pulse bg-accent600"
                  animate={{
                    translateX:
                      (fakeInputRef?.current?.offsetWidth ?? -12) + 12,
                  }}
                />
              )}

              {/* fake input field */}
              <p
                className="text-base font-normal text-gray900"
                ref={fakeInputRef}
              >
                {currentTokenText}
              </p>

              <input
                className="absolute inset-0 opacity-0"
                type={"text"}
                disabled={alreadyStarted}
                value={currentTokenText}
                onChange={(e) => {
                  //remove all non numbers from e.target.value
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setCurrentTokenText(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab" || e.key === " ") {
                    enterToken();
                  }
                }}
                onFocus={() => setTokenInputActive(true)}
                onBlur={() => {
                  enterToken();
                  setTokenInputActive(false);
                }}
              />
            </div>
            <p className="mt-1.5 text-sm font-normal text-gray600">
              {tokens.length} {t("rewards.traitstokensAdded")}
            </p>
          </div>
        </div>
      )}

      <div className="mt-16 md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-sm font-medium leading-6 text-gray700">
            {t("rewards.newReward.whichTraisCanClaim")}
          </h3>
          <p className="text-sm font-normal text-gray600">
            {t("rewards.newReward.whichTraisCanClaimSubHeadline")}
          </p>
        </div>
        <div className="col-span-2 grid grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4">
          {restrictions?.map((restriction, i) => {
            return (
              <div
                key={i}
                className="col-span-1 rounded-xl border border-gray200"
              >
                <div className="flex flex-col gap-1 px-4 pt-3 pb-4">
                  <p className="text-sm font-medium text-accent800">
                    {t("rewards.traits")}
                  </p>
                  <div className="flex justify-between">
                    <p className="text-lg font-medium text-gray900">
                      {restriction.attributeTypeKey}
                    </p>
                    <MoreMenu
                      elements={[
                        {
                          function: () => {
                            const newRestrictions = restrictions.filter(
                              (r) =>
                                r.attributeTypeKey !==
                                restriction.attributeTypeKey
                            );
                            setRestrictions(newRestrictions);
                            formData.restrictions = newRestrictions;
                          },
                          text: t("buttons.delete"),
                        },
                      ]}
                    />
                  </div>
                </div>
                <Separator />
                <div className="flex flex-col gap-2 px-4 pb-4 pt-4">
                  <p className="text-sm font-medium text-accent800">
                    {t("rewards.values")}
                  </p>
                  <div className="flex flex-row flex-wrap gap-2 overflow-scroll">
                    {restriction.matchingValues.map((value, i) => (
                      <div className="group relative flex flex-shrink-0 rounded-md border border-gray300 px-2 py-0.5 text-sm font-medium text-gray700 hover:opacity-70">
                        {value}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
          {restrictions && restrictions.length > 0 ? (
            <div className="col-span-1">
              <NonEmptyAddElement
                title={t("rewards.addTrait")}
                subtitle={t("rewards.addTraitSubline")}
                onClick={() => setShowTraitsSelector(true)}
              />
            </div>
          ) : (
            <EmptyAddElement
              title={t("rewards.noTraitsAdded")}
              subtitle={t("rewards.noTraitsAddedSubHeadline")}
              buttonTitle={t("buttons.addTrait")}
              onClick={() => setShowTraitsSelector(true)}
            />
          )}
          <SelectTraitsDialog
            isOpen={showTraitsSelector}
            close={(trait) => {
              if (trait) {
                // don't allow duplicates
                setRestrictions((cur) => {
                  const newRestrictions = cur;
                  const index = cur.findIndex(
                    (r) => r.attributeTypeKey == trait.attributeTypeKey
                  );
                  if (index >= 0) {
                    newRestrictions[index] = trait;
                  } else {
                    newRestrictions.push(trait);
                  }
                  return newRestrictions;
                });
              }
              setShowTraitsSelector(false);
            }}
          />
        </div>
      </div>
      <div className="sticky bottom-0 w-full bg-white">
        <div className="flex h-16 items-center justify-end gap-2">
          <Button
            style={ButtonStyle.OUTLINED}
            text={t("buttons.back")}
            onClick={onBack}
          />
          <Button
            disabled={
              formData.selectedProducts === undefined ||
              formData.selectedProducts.length <= 0 ||
              formData.title === undefined ||
              formData.title.length <= 0 ||
              formData.description === undefined ||
              formData.description.length <= 0
            }
            text={editingReward ? t("buttons.save") : t("buttons.addReward")}
            onClick={() => setConfirmAddRewardDialogOpen(true)}
          />
        </div>
      </div>
    </div>
  );
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminApiKeyCreate,
  ApiKey,
  ApiKeyCreate,
  BillingAddress,
  Claim,
  ClaimCounts,
  ClaimRequest,
  ClaimUpdate,
  ErrorResponse,
  JSONObject,
  NonceResponse,
  PageClaimDetail,
  Product,
  ProductCreateUpdate,
  ProductDetail,
  ProjectUpdate,
  ProjectUser,
  Reward,
  RewardClaim,
  RewardCreate,
  RewardDetail,
  RewardUpdate,
  StatisticsData,
  User,
  UserCreate,
  VerifyContractOwner,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User Reward
   * @name UpdateUserReward
   * @summary Updates a reward of the currently authenticated user.
   * @request PUT:/api/user/reward/{id}
   * @secure
   */
  updateUserReward = (id: string, data: RewardUpdate, params: RequestParams = {}) =>
    this.request<Reward, ErrorResponse>({
      path: `/api/user/reward/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Reward
   * @name DeleteUserReward
   * @summary Deletes the reward with the provided id (if owned by the currently authenticated user). Also deletes claims associated with the deleted reward.
   * @request DELETE:/api/user/reward/{id}
   * @secure
   */
  deleteUserReward = (id: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/reward/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Public User
   * @name SendResetPasswordLink
   * @summary Sends out a new password reset link & token to the user with the provided email address.
   * @request GET:/api/user/public/reset-pw
   */
  sendResetPasswordLink = (
    query: {
      email: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/public/reset-pw`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Public User
   * @name SetPassword
   * @summary Sets a new password for a user.
   * @request PUT:/api/user/public/reset-pw
   */
  setPassword = (
    query?: {
      /** The new password to set */
      password?: string;
      /** The token received via email in the reset password link */
      token?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/public/reset-pw`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Project
   * @name UpdateProject
   * @summary Updates the currently authenticated users project data.
   * @request PUT:/api/user/project
   * @secure
   */
  updateProject = (data: ProjectUpdate, params: RequestParams = {}) =>
    this.request<User, ErrorResponse>({
      path: `/api/user/project`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Project
   * @name RefreshProjectMetaData
   * @summary Refreshes the project meta data associated with the authenticated user.
   * @request PUT:/api/user/project/refresh-meta-data
   * @secure
   */
  refreshProjectMetaData = (params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/project/refresh-meta-data`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Product
   * @name UpdateUserProduct
   * @summary Updates a product of the currently authenticated user.
   * @request PUT:/api/user/product/{id}
   * @secure
   */
  updateUserProduct = (id: string, data: ProductCreateUpdate, params: RequestParams = {}) =>
    this.request<Product, ErrorResponse>({
      path: `/api/user/product/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Product
   * @name DeleteUserProduct
   * @summary Deletes the product with the provided id (if owned by the currently authenticated user)
   * @request DELETE:/api/user/product/{id}
   * @secure
   */
  deleteUserProduct = (id: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/product/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Claims
   * @name UpdateClaim
   * @summary Updates the claim with the provided id
   * @request PUT:/api/user/claim/{id}
   * @secure
   */
  updateClaim = (id: string, data: ClaimUpdate, params: RequestParams = {}) =>
    this.request<Claim, ErrorResponse>({
      path: `/api/user/claim/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name SetBillingAddress
   * @summary Sets the users billing address.
   * @request PUT:/api/user/billing-address
   * @secure
   */
  setBillingAddress = (data: BillingAddress, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/billing-address`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Reward
   * @name GetUserRewards
   * @summary Gets all rewards of the currently authenticated user.
   * @request GET:/api/user/reward
   * @secure
   */
  getUserRewards = (
    query?: {
      status?: "Active" | "Upcoming" | "Past";
    },
    params: RequestParams = {},
  ) =>
    this.request<RewardDetail[], ErrorResponse>({
      path: `/api/user/reward`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Reward
   * @name CreateUserReward
   * @summary Creates a new reward for the currently authenticated user.
   * @request POST:/api/user/reward
   * @secure
   */
  createUserReward = (data: RewardCreate, params: RequestParams = {}) =>
    this.request<Reward, ErrorResponse>({
      path: `/api/user/reward`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Reward
   * @name SetRewardImage
   * @summary Sets the image for the reward with the provided id.
   * @request POST:/api/user/reward/{id}/image
   * @secure
   */
  setRewardImage = (
    id: string,
    data: {
      /**
       * The image to set. If null, the current image is removed.
       * @format binary
       */
      image?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/reward/${id}/image`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Project
   * @name SetProjectImage
   * @summary Sets the currently authenticated users project image / logo.
   * @request POST:/api/user/project/image
   * @secure
   */
  setProjectImage = (
    data: {
      /**
       * The image to set. If null, the current image is removed from project.
       * @format binary
       */
      image?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/project/image`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Product
   * @name GetUserProducts
   * @summary Gets all products of the currently authenticated user.
   * @request GET:/api/user/product
   * @secure
   */
  getUserProducts = (params: RequestParams = {}) =>
    this.request<ProductDetail[], ErrorResponse>({
      path: `/api/user/product`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Product
   * @name AddUserProduct
   * @summary Creates a new product for the currently authenticated user.
   * @request POST:/api/user/product
   * @secure
   */
  addUserProduct = (data: ProductCreateUpdate, params: RequestParams = {}) =>
    this.request<Product, ErrorResponse>({
      path: `/api/user/product`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Product
   * @name SetProductImages
   * @summary Sets the product images. Removes all existing images of the product and replaces them with the provided images.
   * @request POST:/api/user/product/images/{id}
   * @secure
   */
  setProductImages = (
    id: string,
    data: {
      /** The product images, maximum: 3 */
      images: File[];
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/product/images/${id}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Product
   * @name AddProductImage
   * @summary Adds an image to the product.
   * @request POST:/api/user/product/image/{id}
   * @secure
   */
  addProductImage = (
    id: string,
    data: {
      /** @format binary */
      image: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/product/image/${id}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags User API Keys
   * @name GetUserApiKeys
   * @summary Gets all API keys of the currently authenticated user.
   * @request GET:/api/user/api-key
   * @secure
   */
  getUserApiKeys = (params: RequestParams = {}) =>
    this.request<ApiKey[], ErrorResponse>({
      path: `/api/user/api-key`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User API Keys
   * @name CreateUserApiKey
   * @summary Creates a new API key for the currently authenticated user.
   * @request POST:/api/user/api-key
   * @secure
   */
  createUserApiKey = (data: ApiKeyCreate, params: RequestParams = {}) =>
    this.request<ApiKey, ErrorResponse>({
      path: `/api/user/api-key`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User API Keys
   * @name DeleteUserApiKey
   * @summary Deletes the provided API key for the currently authenticated user.
   * @request DELETE:/api/user/api-key
   * @secure
   */
  deleteUserApiKey = (
    query: {
      apiKey: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/api-key`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API Claim
   * @name ClaimRewardByUserId
   * @summary Claims a reward based on the provided claim request from the provided project (userId).
   * @request POST:/api/public/claim/{userId}
   */
  claimRewardByUserId = (userId: string, data: ClaimRequest, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/public/claim/${userId}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags APIKey Claim
   * @name ClaimReward
   * @summary Claims a reward based on the provided claim request.
   * @request POST:/api/closed/claim
   * @secure
   */
  claimReward = (data: ClaimRequest, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/closed/claim`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin
   * @name CreateUser
   * @summary Creates a new user and initiates password setting via e-mail.
   * @request POST:/api/admin/user
   * @secure
   */
  createUser = (data: UserCreate, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/admin/user`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin
   * @name CreateApiKeyAdmin
   * @summary Creates a new API key
   * @request POST:/api/admin/api-key
   * @secure
   */
  createApiKeyAdmin = (data: AdminApiKeyCreate, params: RequestParams = {}) =>
    this.request<ApiKey, ErrorResponse>({
      path: `/api/admin/api-key`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin
   * @name DeleteApiKeyAdmin
   * @summary Deletes the provided API key
   * @request DELETE:/api/admin/api-key
   * @secure
   */
  deleteApiKeyAdmin = (
    query: {
      apiKey: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/admin/api-key`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Reward
   * @name SetRewardEnabled
   * @summary Sets the enabled status of the reward with the provided ID.
   * @request PATCH:/api/user/reward/{id}/{enabled}
   * @secure
   */
  setRewardEnabled = (id: string, enabled: boolean, params: RequestParams = {}) =>
    this.request<Reward, ErrorResponse>({
      path: `/api/user/reward/${id}/${enabled}`,
      method: "PATCH",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Project
   * @name VerifyContract
   * @summary Sets the users verified contract. If successful, the provided contract is associated with the user and meta data is refreshed.
   * @request PATCH:/api/user/project/verify-contract
   * @secure
   */
  verifyContract = (data: VerifyContractOwner, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/project/verify-contract`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name Me
   * @summary Gets the currently authenticated user.
   * @request GET:/api/user
   * @secure
   */
  me = (params: RequestParams = {}) =>
    this.request<User, ErrorResponse>({
      path: `/api/user`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name Delete
   * @summary Deletes the currently authenticated user and all it's claims, rewards and products
   * @request DELETE:/api/user
   * @secure
   */
  delete = (params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/user`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Stats
   * @name GetStats
   * @summary Gets the statistics of the currently authenticated user.
   * @request GET:/api/user/stats
   * @secure
   */
  getStats = (params: RequestParams = {}) =>
    this.request<StatisticsData, ErrorResponse>({
      path: `/api/user/stats`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags File
   * @name GetImage
   * @summary Gets an image by id
   * @request GET:/api/user/file/image/{id}
   * @secure
   */
  getImage = (id: string, params: RequestParams = {}) =>
    this.request<string, ErrorResponse>({
      path: `/api/user/file/image/${id}`,
      method: "GET",
      secure: true,
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags File
   * @name GetImageAsResource
   * @summary Gets an image by id as input stream resource
   * @request GET:/api/user/file/image-resource/{id}
   * @secure
   */
  getImageAsResource = (id: string, params: RequestParams = {}) =>
    this.request<File, ErrorResponse>({
      path: `/api/user/file/image-resource/${id}`,
      method: "GET",
      secure: true,
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags User Claims
   * @name GetUserClaims
   * @summary Gets all claims related to the currently authenticated user
   * @request GET:/api/user/claim
   * @secure
   */
  getUserClaims = (
    query?: {
      /**
       * Zero-based page index (0..N)
       * @min 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
      status?: "Claimed" | "Completed" | "Cancelled";
    },
    params: RequestParams = {},
  ) =>
    this.request<PageClaimDetail, ErrorResponse>({
      path: `/api/user/claim`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Claims
   * @name GetClaimsCsv
   * @summary Gets all claims related to the currently authenticated user as .csv
   * @request GET:/api/user/claim/csv
   * @secure
   */
  getClaimsCsv = (
    query?: {
      delimiter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, ErrorResponse>({
      path: `/api/user/claim/csv`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Claims
   * @name GetUserClaimCounts
   * @summary Gets all counts concerning claims related to the currently authenticated user
   * @request GET:/api/user/claim/counts
   * @secure
   */
  getUserClaimCounts = (params: RequestParams = {}) =>
    this.request<ClaimCounts, ErrorResponse>({
      path: `/api/user/claim/counts`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Stats
   * @name Version
   * @request GET:/api/stats/version
   */
  version = (params: RequestParams = {}) =>
    this.request<string, ErrorResponse>({
      path: `/api/stats/version`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stats
   * @name GetMemoryStatistics
   * @request GET:/api/stats/mem-stat
   */
  getMemoryStatistics = (params: RequestParams = {}) =>
    this.request<JSONObject, ErrorResponse>({
      path: `/api/stats/mem-stat`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stats
   * @name Host
   * @request GET:/api/stats/host
   */
  host = (params: RequestParams = {}) =>
    this.request<string, ErrorResponse>({
      path: `/api/stats/host`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stats
   * @name Status
   * @request GET:/api/stats/health
   */
  status = (params: RequestParams = {}) =>
    this.request<string, ErrorResponse>({
      path: `/api/stats/health`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API User
   * @name GetUserByProjectName
   * @summary Gets the project user (containing project information) for a provided project name (url appendix)
   * @request GET:/api/public/user/{projectName}
   */
  getUserByProjectName = (projectName: string, params: RequestParams = {}) =>
    this.request<ProjectUser, ErrorResponse>({
      path: `/api/public/user/${projectName}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API Reward
   * @name RewardsByUserId
   * @summary Gets all rewards available for a specific user id
   * @request GET:/api/public/reward/user/{userId}
   */
  rewardsByUserId = (userId: string, params: RequestParams = {}) =>
    this.request<RewardDetail[], ErrorResponse>({
      path: `/api/public/reward/user/${userId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API Reward
   * @name RewardsByProjectName
   * @summary Gets all rewards available for a specific project name
   * @request GET:/api/public/reward/project/{projectName}
   */
  rewardsByProjectName = (projectName: string, params: RequestParams = {}) =>
    this.request<RewardDetail[], ErrorResponse>({
      path: `/api/public/reward/project/${projectName}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API Reward
   * @name GetRewardsPotentiallyAvailableAndClaimsByUserAndTokenId
   * @summary Gets all rewards where the provided token id meets the restrictions for claim of rewards from the provided userId and all claims that have already been performed
   * @request GET:/api/public/reward/potential/claims/{userId}
   */
  getRewardsPotentiallyAvailableAndClaimsByUserAndTokenId = (
    userId: string,
    query: {
      /** @format int64 */
      tokenId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<RewardClaim[], ErrorResponse>({
      path: `/api/public/reward/potential/claims/${userId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API Reward
   * @name RewardsAndClaimsByUserAndTokenId
   * @summary Gets all rewards and claims for a specific user id and token id
   * @request GET:/api/public/reward/claims/{userId}
   */
  rewardsAndClaimsByUserAndTokenId = (
    userId: string,
    query: {
      /** @format int64 */
      tokenId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<RewardClaim[], ErrorResponse>({
      path: `/api/public/reward/claims/${userId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API Nonce
   * @name GetNonce
   * @summary Gets a new signature nonce for a wallet address (invalidates previously created nonces for wallet). The returned "message" containing the nonce must be signed and used as the signature when claiming a reward.
   * @request GET:/api/public/nonce
   */
  getNonce = (
    query: {
      /** The wallet address to create the nonce for. This must be the same wallet that is later used for claiming. */
      walletAddress: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<NonceResponse, ErrorResponse>({
      path: `/api/public/nonce`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API File
   * @name GetImage1
   * @summary Gets an image by id
   * @request GET:/api/public/file/image/{id}
   */
  getImage1 = (id: string, params: RequestParams = {}) =>
    this.request<string, ErrorResponse>({
      path: `/api/public/file/image/${id}`,
      method: "GET",
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags Public API File
   * @name GetImageAsResource1
   * @summary Gets an image by id as input stream resource
   * @request GET:/api/public/file/image-resource/{id}
   */
  getImageAsResource1 = (id: string, params: RequestParams = {}) =>
    this.request<File, ErrorResponse>({
      path: `/api/public/file/image-resource/${id}`,
      method: "GET",
      format: "blob",
      ...params,
    });
  /**
   * @description Logs
   *
   * @tags Logging
   * @name Log
   * @request GET:/api/logging
   */
  log = (
    query?: {
      /** @format int32 */
      count?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse>({
      path: `/api/logging`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Error
   * @name ErrorCodes
   * @summary Returns all available error codes.
   * @request GET:/api/error
   */
  errorCodes = (params: RequestParams = {}) =>
    this.request<
      (
        | "ValidationFailed"
        | "UploadSizeTooLarge"
        | "UserAlreadyExists"
        | "EntryAlreadyExists"
        | "ErrorUpdatingData"
        | "ErrorStoringData"
        | "BadCredentials"
        | "Expired"
        | "NotFound"
        | "BadRequest"
        | "InvalidData"
        | "MissingData"
        | "ErrorSendingEmail"
        | "BadMediaType"
        | "AccessDenied"
        | "WrongPassword"
        | "WrongUsername"
        | "Unauthorized"
        | "ProductAlreadyClaimed"
        | "ProductNotAvailableForToken"
        | "ProductNotAvailableYet"
        | "ProductNotEnabled"
        | "SignatureMismatch"
        | "AddressNotTokenOwner"
        | "InvalidAPIKey"
        | "APIKeyUnknown"
        | "MissingAPIKey"
        | "APIKeyOriginMismatch"
        | "MissingContractAddress"
        | "ErrorReadingContract"
        | "ErrorReadingIPFS"
        | "LimitExceeded"
      )[],
      ErrorResponse
    >({
      path: `/api/error`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags APIKey User
   * @name GetUser
   * @summary Gets the authenticated project user (containing project information)
   * @request GET:/api/closed/user
   * @secure
   */
  getUser = (params: RequestParams = {}) =>
    this.request<ProjectUser, ErrorResponse>({
      path: `/api/closed/user`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags APIKey Rewards
   * @name GetRewards
   * @summary Gets all available rewards
   * @request GET:/api/closed/reward
   * @secure
   */
  getRewards = (params: RequestParams = {}) =>
    this.request<RewardDetail[], ErrorResponse>({
      path: `/api/closed/reward`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags APIKey Rewards
   * @name GetRewardsPotentiallyAvailableAndClaimsByTokenId
   * @summary Gets all rewards where the provided token id meets the restrictions for claim and all claims that have already been performed
   * @request GET:/api/closed/reward/potential/claims
   * @secure
   */
  getRewardsPotentiallyAvailableAndClaimsByTokenId = (
    query: {
      /** @format int64 */
      tokenId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<RewardClaim[], ErrorResponse>({
      path: `/api/closed/reward/potential/claims`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags APIKey Rewards
   * @name GetRewardsAvailableForClaimAndPerformedClaimsByTokenId
   * @summary Gets all rewards available for claim by the provided token id including the already performed claims
   * @request GET:/api/closed/reward/available/claims
   * @secure
   */
  getRewardsAvailableForClaimAndPerformedClaimsByTokenId = (
    query: {
      /** @format int64 */
      tokenId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<RewardClaim[], ErrorResponse>({
      path: `/api/closed/reward/available/claims`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Admin
   * @name GetClaimsCsv1
   * @summary Gets all claims for the provided user id as .csv
   * @request GET:/api/admin/claims-csv/{userId}
   * @secure
   */
  getClaimsCsv1 = (
    userId: string,
    query?: {
      delimiter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, ErrorResponse>({
      path: `/api/admin/claims-csv/${userId}`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Product
   * @name DeleteProductImage
   * @summary Deletes an image from the product.
   * @request DELETE:/api/user/product/image/{id}/{imageId}
   * @secure
   */
  deleteProductImage = (id: string, imageId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/user/product/image/${id}/${imageId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

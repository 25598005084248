import { UsersIcon } from "@heroicons/react/24/outline";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../util";

type Props = {
  title: string;
  subtitle?: string;
  selected: boolean;
  onClick: () => void;
  icon: JSX.Element;
};

export default function LongCheckbox({
  title,
  subtitle = undefined,
  selected,
  onClick,
  icon,
}: Props) {
  const style = selected
    ? "border-accent600 bg-accent50 border-2 p-4"
    : "border-gray200 border p-[17px]";

  return (
    <button
      className={classNames(
        style,
        "relative flex w-full flex-row items-center justify-between gap-4 rounded-md p-4 text-left"
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          "flex h-12 w-12 items-center justify-center rounded-full border-[8px]",
          selected
            ? "border-accent50 bg-accent100 mix-blend-multiply"
            : "border-accent50 bg-accent100"
        )}
      >
        <div className="button-heroicon-stroke h-5 w-5 rounded-full text-accent">
          {icon}
        </div>
      </div>
      <div className="flex-1">
        <p
          className={classNames(
            "text-sm font-medium",
            selected ? "text-accent800" : "text-gray700"
          )}
        >
          {title}
        </p>
        {subtitle && (
          <p
            className={classNames(
              selected ? "text-accent700" : "text-gray600",
              "text-sm font-normal"
            )}
          >
            {subtitle}
          </p>
        )}
      </div>
      {selected && (
        <div className="h-full items-start justify-start">
          <CheckBadgeIcon className="absolute top-2 right-2 h-4 w-4 text-accent" />
        </div>
      )}
    </button>
  );
}

import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";

type Props = {
  title: string;
  subtitle?: string;
  onClick: () => void;
};

export default function NonEmptyAddElement({
  title,
  subtitle = undefined,
  onClick,
}: Props) {
  const { t } = useTranslation();
  return (
    <button
      className="mt-5 flex h-60 w-60 flex-col items-center justify-center rounded-xl border border-gray200 px-4 hover:opacity-70 md:col-span-2 md:mt-0"
      onClick={onClick}
    >
      <PlusCircleIcon className="h-10 w-10 text-gray400" />
      <div className="text-center">
        <h3 className="text-base font-semibold text-gray500">{title}</h3>
        <p className="text-sm font-normal text-gray600">{subtitle}</p>
      </div>
    </button>
  );
}

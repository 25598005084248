import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import AuthContext from "./API/AuthContext";
import UserContext from "./API/UserContext";
import App from "./App";
import { ErrorHandler } from "./Error/ErrorContext";
import "./i18n/config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Web3Provider } from "./web3/Web3Context";

ReactDOM.render(
  <React.StrictMode>
    <ErrorHandler>
      <AuthContext>
        <UserContext>
          <BrowserRouter>
            <Web3Provider>
              <App />
            </Web3Provider>
          </BrowserRouter>
        </UserContext>
      </AuthContext>
    </ErrorHandler>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

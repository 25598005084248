import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

type Props = {
  elements: {
    function: () => void;
    text: string;
  }[];
};

export default function MoreMenu({ elements }: Props) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="rounded-button -z-10 h-12 items-center justify-center px-2 text-base font-semibold text-gray700 disabled:opacity-50">
        <EllipsisVerticalIcon
          className="h-5 w-5 text-violet-200"
          aria-hidden="true"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 top-10 z-10 w-40 origin-top-right divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {elements.map((element, i) => {
            return (
              <Menu.Item key={`menu-item${i}`}>
                {({ active }) => (
                  <div
                    className={`${
                      active
                        ? "bg-accent100 text-gray-900"
                        : "bg-white text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={element.function}
                  >
                    {element.text}
                  </div>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

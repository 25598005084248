import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAPIKey } from "../../API/APIKeyContext";
import { RewardDetail } from "../../API/data-contracts";
import AppearingDialog from "../../GeneralComponents/AppearingDialog";
import { classNames, dateToYYYYmmdd } from "../../util";
import { useWeb3 } from "../../web3/Web3Context";

type Props = {
  open: boolean;
  onClose: () => void;
  userId: string;
  selectedProductClaim: RewardDetail;
  tokenId: number;
  account: string;
  onClaimed: () => void;
};

export default function ClaimDialog({
  open,
  onClose,
  userId,
  selectedProductClaim,
  tokenId,
  account,
  onClaimed,
}: Props) {
  const { signMessage } = useWeb3();
  const { api } = useAPIKey();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [submitted, setSubmitted] = useState<boolean | undefined>();

  const claimProduct = async (data: any) => {
    try {
      const nonceResult = await api.getNonce({ walletAddress: account });
      const nonce = nonceResult.data;
      const signature = await signMessage(nonce.message);

      if (signature) {
        const claimResult = await api.claimRewardByUserId(userId, {
          signature,
          walletAddress: account,
          tokenId,
          rewardId: selectedProductClaim.id,
          contactInformation: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            address: {
              street: data.street,
              city: data.city,
              postCode: data.postCode,
              country: data.country,
              string: "",
            },
            birthDay: data.birthDay,
          },
        });
        setSubmitted(true);
        onClaimed();
      } else {
        setSubmitted(false);
      }
    } catch (error) {
      setSubmitted(false);
      console.error(error);
    }
  };

  const close = () => {
    onClose();
    setTimeout(() => {
      setSubmitted(undefined);
    }, 200);
  };

  let thirteenYearsAgo = new Date();
  thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);
  const maxDate = dateToYYYYmmdd(thirteenYearsAgo);

  const success = (
    <>
      <div className="flex items-center justify-between gap-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("form.submittedHeadline")}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t("form.submittedSubtitle")}
          </p>
        </div>
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
      </div>
    </>
  );

  const failed = (
    <>
      <div className="flex items-center justify-between gap-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("form.errorHeadline")}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t("form.errorSubtitle")}
          </p>
        </div>
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
      </div>
    </>
  );

  const form = (
    <>
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {t("form.headline")}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{t("form.subtitle")}</p>
      </div>
      <form
        onSubmit={handleSubmit(claimProduct)}
        className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
      >
        <div className="sm:col-span-3">
          <label
            htmlFor="first-name"
            className={classNames(
              errors["firstName"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.firstName")}
          </label>
          <input
            type="text"
            autoComplete="given-name"
            className={classNames(
              errors["firstName"] ? "" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("firstName", { required: true, maxLength: 80 })}
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="first-name"
            className={classNames(
              errors["lastName"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.lastName")}
          </label>
          <input
            type="text"
            className={classNames(
              errors["lastName"] ? "" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("lastName", { required: true, maxLength: 100 })}
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="email"
            className={classNames(
              errors["email"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.email")}
          </label>
          <input
            type="text"
            className={classNames(
              errors["email"] ? "" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("email", {
              required: true,
              pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
            })}
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="birthDay"
            className={classNames(
              errors["birthDay"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.birthday")}
          </label>
          <input
            type="date"
            min="1900-01-01"
            max={maxDate}
            className={classNames(
              errors["birthDay"] ? "text-red-600 placeholder:text-red-600" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("birthDay", { required: false })}
          />
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="street-address"
            className={classNames(
              errors["street"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.street")}
          </label>
          <input
            type="text"
            className={classNames(
              errors["street"] ? "" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("street", { required: false })}
          />
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="city"
            className={classNames(
              errors["city"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.city")}
          </label>
          <input
            type="text"
            className={classNames(
              errors["city"] ? "" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("city", { required: false })}
          />
        </div>
        <div className="sm:col-span-1">
          <label
            htmlFor="postal-code"
            className={classNames(
              errors["postCode"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.postCode")}
          </label>
          <input
            type="text"
            className={classNames(
              errors["postCode"] ? "" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("postCode", { required: false })}
          />
        </div>
        <div className="sm:col-span-1">
          <label
            htmlFor="country"
            className={classNames(
              errors["country"] ? "text-red-600" : "text-gray-700",
              "block text-sm font-medium"
            )}
          >
            {t("form.country")}
          </label>
          <input
            type="text"
            className={classNames(
              errors["country"] ? "" : "",
              "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            )}
            {...register("country", { required: false })}
          />
        </div>

        {/* <ErrorMessage
            errors={errors}
            name="firstName"
            // name="singleErrorInput"
            render={({ message }) => <p>{message}</p>}
          /> */}

        <input
          type="submit"
          className="special-button-gradient mt-4 h-[46px] w-full cursor-pointer rounded-lg border border-accent600 text-base font-semibold text-white hover:opacity-70"
        />
      </form>
    </>
  );

  return (
    <AppearingDialog open={open} onClose={close}>
      <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
        {submitted === undefined ? form : submitted === true ? success : failed}
      </div>
    </AppearingDialog>
  );
}

import {
  ArrowRightIcon,
  ArrowTrendingUpIcon,
  CircleStackIcon,
  EllipsisVerticalIcon,
  GiftIcon,
  SparklesIcon,
  UserGroupIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { RewardDetail, StatisticsData } from "../API/data-contracts";
import HeaderLabel from "../Components/Label/HeaderLabel";
import Button, { ButtonStyle } from "../Components/UI/Button";
import { DashboardCards } from "../Components/UI/DashboardCards";
import Dot from "../Components/UI/Dot";
import Separator from "../Components/UI/Separator";
import { TableLoadingIndicator } from "../Components/UI/TableLoadingIndicator";
import Tag from "../Components/UI/Tag";
import { shortDate } from "../util";
import { mapFilterText } from "./Rewards";

type Props = {};

export default function Home({}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { api } = useAuth();

  const [stats, setStats] = useState<StatisticsData | undefined>();
  const [statsLoading, setStatsLoading] = useState(false);

  useEffect(() => {
    setStatsLoading(true);
    api
      .getStats({ secure: true })
      .then((res) => {
        setStats(res.data);
      })
      .finally(() => setStatsLoading(false));
  }, [api]);

  const [activeRewards, setActiveRewards] = useState<RewardDetail[]>();
  const [activeRewardsLoading, setActiveRewardsLoading] = useState(false);

  useEffect(() => {
    setActiveRewardsLoading(true);
    api
      .getUserRewards({ status: "Active" })
      .then((res) => {
        setActiveRewards(res.data);
      })
      .finally(() => {
        setActiveRewardsLoading(false);
      });
  }, [api]);

  const [upcomingRewards, setUpcomingRewards] = useState<RewardDetail[]>();
  const [upcomingRewardsLoading, setUpcomingRewardsLoading] = useState(false);

  useEffect(() => {
    setUpcomingRewardsLoading(true);
    api
      .getUserRewards({ status: "Upcoming" })
      .then((res) => {
        setUpcomingRewards(res.data);
      })
      .finally(() => {
        setUpcomingRewardsLoading(false);
      });
  }, [api]);

  return (
    <div className="flex w-full flex-col items-start">
      <div className="mb-8 flex w-full justify-between">
        <HeaderLabel text={t("menu.home")} />
      </div>
      <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
        <DashboardCards
          title={t("dashboard.claims24h")}
          color={"accent"}
          value={stats?.claims ?? 0}
          isLoading={statsLoading}
          percentage={stats?.claimsChangePercent}
          icon={<GiftIcon className="button-heroicon-stroke" />}
        />
        <DashboardCards
          title={t("dashboard.visitors24h")}
          color={"green"}
          value={stats?.visitors ?? 0}
          isLoading={statsLoading}
          percentage={stats?.visitorsChangePercent}
          icon={<ArrowTrendingUpIcon className="button-heroicon-stroke" />}
        />
        <DashboardCards
          title={t("dashboard.openClaims")}
          color={"blue"}
          value={stats?.openClaims ?? 0}
          isLoading={statsLoading}
          icon={<ViewfinderCircleIcon className="button-heroicon-stroke" />}
        />
      </div>

      <div className="mt-16 flex w-full justify-between text-2xl font-bold">
        <p>{t("dashboard.currentlyActiveRewards")}</p>
        <Button
          style={ButtonStyle.OUTLINED}
          text={t("button.showAll")}
          icon={
            <ArrowRightIcon className="button-heroicon-stroke mt-0.5 w-4" />
          }
          onClick={() => {
            navigate("/rewards");
          }}
        />
      </div>
      <div className="mt-4 w-full break-all text-left">
        <table className="w-full">
          {activeRewardsLoading && <TableLoadingIndicator rows={1} />}
          {!activeRewardsLoading &&
            activeRewards?.map((reward, i) => (
              <>
                {i > 0 && <Separator />}
                <tr key={i} className="flex px-6 py-6">
                  <td className="flex flex-[3] items-center justify-start">
                    <h5 className="text-sm font-medium text-sidebarBackground">
                      {reward.title}
                    </h5>
                  </td>
                  <td className="flex flex-[3] items-center justify-start">
                    <p className="text-sm font-normal text-secondaryText">
                      {reward.claimWindow?.start &&
                        shortDate(new Date(reward.claimWindow?.start))}
                      {reward.claimWindow?.start &&
                        reward.claimWindow.end &&
                        " - "}
                      {reward.claimWindow?.end &&
                        shortDate(new Date(reward.claimWindow?.end))}
                    </p>
                  </td>
                  <td className="flex flex-[1] items-center justify-center">
                    <Tag
                      text={mapFilterText(reward.status)?.text ?? ""}
                      style={mapFilterText(reward.status)?.color ?? "gray"}
                      icon={mapFilterText(reward.status)?.icon}
                    />
                  </td>

                  <td className="flex flex-[2] items-center justify-center">
                    <Tag
                      text={t("reward.type." + reward.type)}
                      style="gray"
                      icon={
                        reward.type === "LuckyWinner" ? (
                          <SparklesIcon />
                        ) : reward.type === "Everyone" ? (
                          <UserGroupIcon />
                        ) : (
                          <CircleStackIcon />
                        )
                      }
                    />
                  </td>
                  <td className="flex flex-[2] flex-row items-center justify-center gap-2">
                    {reward.products &&
                      reward.products.length > 0 &&
                      reward.products.map((product: any) => (
                        <Tag text={`${product.title}`} style="accent" />
                      ))}
                  </td>
                  <td className="flex w-5 cursor-pointer flex-row items-center justify-center hover:opacity-70">
                    {/* <EllipsisVerticalIcon className="h-5 w-5 text-gray400" /> */}
                  </td>
                </tr>
              </>
            ))}
          {!activeRewardsLoading && activeRewards?.length === 0 && (
            <p className="text-left text-sm font-medium text-gray400">
              {t("dashboard.noActiveRewards")}
            </p>
          )}
        </table>
      </div>

      <div className="mt-16 flex w-full justify-between text-2xl font-bold">
        {t("dashboard.upcomingRewards")}
      </div>
      <div className="mt-4 w-full break-all text-left">
        <table className="w-full">
          {upcomingRewardsLoading && <TableLoadingIndicator rows={1} />}
          {!upcomingRewardsLoading &&
            upcomingRewards?.map((reward, i) => (
              <>
                {i > 0 && <Separator />}
                <tr key={i} className="flex px-6 py-6">
                  <td className="flex flex-[3] items-center justify-start">
                    <h5 className="text-sm font-medium text-sidebarBackground">
                      {reward.title}
                    </h5>
                  </td>
                  <td className="flex flex-[3] items-center justify-start">
                    <p className="text-sm font-normal text-secondaryText">
                      {reward.claimWindow?.start &&
                        shortDate(new Date(reward.claimWindow?.start))}
                      {reward.claimWindow?.start &&
                        reward.claimWindow.end &&
                        " - "}
                      {reward.claimWindow?.end &&
                        shortDate(new Date(reward.claimWindow?.end))}
                    </p>
                  </td>
                  <td className="flex flex-[1] items-center justify-center">
                    <Tag
                      text={mapFilterText(reward.status)?.text ?? ""}
                      style={mapFilterText(reward.status)?.color ?? "gray"}
                      icon={mapFilterText(reward.status)?.icon}
                    />
                  </td>

                  <td className="flex flex-[2] items-center justify-center">
                    <Tag
                      text={t("reward.type." + reward.type)}
                      style="gray"
                      icon={
                        reward.type === "LuckyWinner" ? (
                          <SparklesIcon />
                        ) : reward.type === "Everyone" ? (
                          <UserGroupIcon />
                        ) : (
                          <CircleStackIcon />
                        )
                      }
                    />
                  </td>
                  <td className="flex flex-[2] flex-row items-center justify-center gap-2">
                    {reward.products &&
                      reward.products.length > 0 &&
                      reward.products.map((product: any) => (
                        <Tag text={`${product.title}`} style="accent" />
                      ))}
                  </td>
                  <td className="flex w-5 cursor-pointer flex-row items-center justify-center hover:opacity-70">
                    {/* <EllipsisVerticalIcon className="h-5 w-5 text-gray400" /> */}
                  </td>
                </tr>
              </>
            ))}
          {!upcomingRewardsLoading && upcomingRewards?.length === 0 && (
            <p className="text-left text-sm font-medium text-gray400">
              {t("dashboard.noUpcomingRewards")}
            </p>
          )}
        </table>
      </div>
    </div>
  );
}

import { CloudArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../util";
import { t } from "i18next";
import { Photo } from "./Photo";

type Props = {
  className?: string;
  imagesToUpload: File[];
  setImagesToUpload: React.Dispatch<React.SetStateAction<File[]>>;
  images: {
    file?: File | undefined;
    id?: string | undefined;
  }[];
  setImages: React.Dispatch<
    React.SetStateAction<
      {
        file?: File | undefined;
        id?: string | undefined;
      }[]
    >
  >;
  imageIdsToDelete: string[];
  setImageIdsToDelete: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ImageUploadInput: React.FC<Props> = ({
  className,
  imagesToUpload,
  setImagesToUpload,
  images,
  setImages,
  imageIdsToDelete,
  setImageIdsToDelete,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          "relative mt-1.5 flex cursor-pointer flex-col items-center justify-center rounded-xl border border-gray200 px-6 py-4 hover:opacity-70 sm:col-span-2",
          images.length >= 3 && "hidden"
        )}
      >
        <div className="flex h-12 w-12 -translate-x-1 -translate-y-1 items-center justify-center rounded-full border-[8px] border-gray50 bg-gray100">
          <div className="button-heroicon-stroke h-5 w-5 rounded-full text-gray600">
            <CloudArrowUpIcon className="button-heroicon-stroke" />
          </div>
        </div>
        <p className="mt-3 text-sm font-normal text-gray600">
          <span className="font-semibold text-accent700">
            {t("product.clickToUpload")}
          </span>{" "}
          {t("product.orDragAndDrop")}
        </p>
        <p className="mt-1 text-xs font-normal text-gray600">
          {t("product.maxImageSize")}
        </p>

        <input
          type="file"
          className={"absolute inset-0 h-full w-full cursor-pointer opacity-0"}
          accept="image/*"
          onChange={async (e) => {
            if (e.target.files) {
              const files = Array.from(e.target.files);
              const file = files[0];

              //add the image to the upload list
              setImagesToUpload((prev) => [...prev, file]);
              //add the image to the image list
              setImages((prev) => [...prev, { file }]);
            }
          }}
          onDrop={async (e) => {
            e.preventDefault();
            if (e.dataTransfer.files) {
              const files = Array.from(e.dataTransfer.files);
              const file = files[0];

              //add the image to the upload list
              setImagesToUpload((prev) => [...prev, file]);
              //add the image to the image list
              setImages((prev) => [...prev, { file }]);
            }
          }}
        />
      </div>

      {/* Current Product Images */}
      <div className="mt-6 flex flex-row gap-2">
        {images?.map((img) => (
          <div
            className="group relative h-24 w-24 cursor-pointer overflow-hidden rounded-lg border border-gray-200"
            onClick={() => {
              if (img.id) {
                //old image - needs to be deleted later
                setImageIdsToDelete((prev) => [...prev, img.id ?? ""]);
              } else {
                //new image - needs to be removed from the upload later array
                setImagesToUpload((prev) =>
                  prev.filter((file) => file !== img.file)
                );
              }
              setImages(images.filter((i) => i !== img));
            }}
          >
            <div className="absolute inset-0 flex items-center justify-center rounded-lg bg-red700 bg-opacity-40 opacity-0 transition-opacity group-hover:opacity-100">
              <TrashIcon className="button-heroicon-stroke h-4 w-4 text-white" />
            </div>
            <Photo id={img.id} file={img.file} />
          </div>
        ))}
      </div>
    </div>
  );
};

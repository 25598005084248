import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { classNames } from "../../util";

type Props = {
  breadcrumbs: { name: string; href?: string; current: boolean }[];
};

export default function Breadcrumbs({ breadcrumbs }: Props) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-1">
        {breadcrumbs.map((breadcrumb, i) => (
          <li key={breadcrumb.name}>
            <div className="flex items-center">
              {i > 0 && (
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              )}
              <Link
                to={breadcrumb.href || ""}
                className={classNames(
                  "ml-1 text-sm",
                  breadcrumb.current
                    ? "rounded-lg bg-accent50 px-2 py-1 font-semibold text-accent700"
                    : "font-medium text-gray-500 hover:text-gray-700"
                )}
                aria-current={breadcrumb.current ? "page" : undefined}
              >
                {breadcrumb.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

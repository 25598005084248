import { Nft } from "alchemy-sdk";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAPIKey } from "../API/APIKeyContext";
import { ProjectUser, RewardClaim, RewardDetail } from "../API/data-contracts";
import ClaimDialog from "../Components/Dialogs/ClaimDialog";
import { AnonymousPhoto } from "../Components/UI/AnonymousPhoto";
import Button, { ButtonStyle } from "../Components/UI/Button";
import { ProjectRewardCard } from "../Components/UI/ProjectRewardCard";
import Separator from "../Components/UI/Separator";
import AppearingDialog from "../GeneralComponents/AppearingDialog";
import { convertIpfs, shortDate } from "../util";
import ConnectWalletButton from "../web3/ConnectWalletButton";
import { alchemy, useWeb3 } from "../web3/Web3Context";

/// This site is a dedicated project page
export default function ProjectPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { api } = useAPIKey();
  const { connectWallet, setContractAddress, account } = useWeb3();

  const [claimSuccessFull, setClaimSuccessFull] = useState(false);
  const [projectUser, setProjectUser] = useState<ProjectUser>();
  const [rewardDetail, setRewardDetail] = useState<RewardDetail>();
  const [projectDoesNotExist, setProjectDoesNotExist] = useState(false);

  const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
  const [pastClaims, setPastClaims] = useState<RewardClaim[]>();
  const [rewardClaims, setRewardClaims] = useState<RewardClaim[]>();
  const [upcomingRewardClaims, setUpcomingRewardClaims] =
    useState<RewardClaim[]>();
  const [nft, setNft] = useState<Nft>();
  const [totalNfts, setTotalNfts] = useState(0);

  const [selectedProductClaim, setSelectedProductClaim] =
    useState<RewardClaim>();

  const projectName = window.location.href.split("/").pop();

  const query = searchParams.get("tokenId");
  const tokenIdQuery = query ? Number(query) : 1;

  const [tokenId, setTokenId] = useState<number | undefined>(tokenIdQuery);
  const [inputTokenId, setInputTokenId] = useState<number | undefined>(
    tokenIdQuery
  );

  const setRewardResponse = (rewards: RewardClaim[]) => {
    const upcoming: RewardClaim[] = [];
    const active: RewardClaim[] = [];
    const past: RewardClaim[] = [];
    for (const reward of rewards) {
      if (reward.reward.status === "Active") {
        active.push(reward);
      } else if (reward.reward.status === "Upcoming") {
        upcoming.push(reward);
      } else if (reward.reward.status === "Past") {
        past.push(reward);
      }
    }
    setRewardClaims(active ?? []);
    setUpcomingRewardClaims(upcoming ?? []);
    setPastClaims(past ?? []);
  };

  const queryProject = async () => {
    if (projectName !== undefined) {
      try {
        const projectUserResponse = await api.getUserByProjectName(projectName);
        setProjectUser(projectUserResponse.data);
        setContractAddress(projectUserResponse.data.contract);

        // pending design
        // const rewardsResponse = await api.rewardsByProjectName(projectName);
        // if (rewardsResponse.ok) {
        //   setRewardResponse(rewardsResponse.data);
        // } else {
        //   console.error(rewardsResponse.error);
        // }
      } catch (error) {
        setProjectDoesNotExist(true);
      }
    } else {
      setProjectDoesNotExist(true);
    }
  };

  const queryNFTCount = async () => {
    if (projectUser?.contract) {
      const allNfts = await alchemy.nft.getNftsForContract(
        projectUser.contract
      );
      setTotalNfts(allNfts.nfts.length);
    }
  };

  const queryNFT = async () => {
    if (projectUser?.contract && projectUser?.id && tokenId !== undefined) {
      setLoadingTokenInfo(true);
      setRewardClaims([]);
      setUpcomingRewardClaims([]);
      setPastClaims([]);

      const potentialRewards =
        await api.getRewardsPotentiallyAvailableAndClaimsByUserAndTokenId(
          projectUser.id,
          { tokenId }
        );
      if (potentialRewards.ok) {
        setRewardResponse(potentialRewards.data);
      } else {
        setRewardResponse([]);
      }
      setLoadingTokenInfo(false);

      const nft = await alchemy.nft.getNftMetadata(
        projectUser.contract,
        tokenId
      );
      setNft(nft);
    } else {
      setNft(undefined);
    }
  };

  const checkNowClicked = () => {
    if (inputTokenId && totalNfts && inputTokenId >= totalNfts) {
      const id = totalNfts - 1;
      setInputTokenId(id);
      setTokenId(id);
      setSearchParams({ tokenId: `${id}` });
    } else {
      setTokenId(inputTokenId);
      setSearchParams({ tokenId: `${inputTokenId}` });
    }
  };

  useEffect(() => {
    queryNFT();
  }, [tokenId, projectUser]);

  useEffect(() => {
    queryProject();
    queryNFTCount();
  }, []);

  if (projectUser === undefined) {
    // loading
    return <div className="project-background-gradient flex h-screen w-full" />;
  }

  if (projectDoesNotExist) {
    return (
      <div className="relative h-screen w-full">
        {/* Content */}
        <div className="project-background-gradient flex h-full w-full flex-col items-center justify-center px-8 text-white lg:px-16">
          <h1>Project {projectName} does not exist.</h1>
        </div>
      </div>
    );
  }

  if (projectUser?.contract === undefined) {
    return (
      <div className="relative h-screen w-full">
        {/* Content */}
        <div className="project-background-gradient flex h-full w-full flex-col items-center justify-center px-8 text-white lg:px-16">
          <h1>Smart contract for project {projectName} not setup yet.</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full">
      {/* Content */}
      <div className="project-background-gradient flex w-full flex-col items-center px-8 lg:px-16">
        <header className="flex h-16 w-full flex-row items-center justify-between px-4">
          {/* Smart Contract */}
          <p className="mt-2 max-w-xl text-xs font-medium text-gray200">
            This project uses the contract:{" "}
            <a
              href={"https://etherscan.io/address/" + projectUser?.contract}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              {projectUser?.contract}
            </a>
          </p>
          <div className="w-48">
            <ConnectWalletButton />
          </div>
        </header>
        {/* Top Logo */}
        {projectUser?.projectImageId ? (
          <div className="relative mt-14 flex h-24 w-24 shrink-0 overflow-hidden rounded-3xl bg-gray200">
            <AnonymousPhoto
              id={projectUser.projectImageId}
              className="!rounded-3xl"
            />
          </div>
        ) : (
          // placeholder photo
          <div className="mt-16"></div>
        )}
        {/* Header Text */}
        <div className="mt-5 flex">
          <h1 className="font-spaceGrotesk text-6xl font-bold text-white">
            {projectUser?.projectDisplayName ?? ""}
          </h1>
        </div>
        {/* Description */}
        <p className="max-w-xl text-base font-medium text-gray200">
          {projectUser?.projectDescription ?? ""}
        </p>
        {/* TextField + Button */}
        <div>
          {/* Text Input */}
          <div>
            <div className="shadow-xs mt-14 flex h-[46px] flex-row items-center justify-center overflow-hidden rounded-lg border border-gray300">
              <div className="flex h-full items-center justify-center border-r border-r-gray300 bg-white px-3 text-base font-normal text-gray600">
                #
              </div>
              <input
                className="bg-white py-2.5 pl-3 pr-2.5 text-base font-normal text-gray500 placeholder:text-gray500
                focus:border-0 focus:outline-none focus:ring-0"
                placeholder={t("projectPage.enterTokenHere")}
                inputMode={"numeric"}
                value={inputTokenId ?? "1"}
                onChange={(e) => setInputTokenId(Number(e.target.value))}
              />

              {/* <div
                className="flex h-full cursor-pointer items-center justify-center bg-white px-4 text-base font-normal text-gray400 hover:text-accent700"
                onClick={() => {
                  // TODO: Implement help
                }}
              >
                <QuestionMarkCircleIcon className="h-4" />
              </div> */}
            </div>
          </div>

          {/* Check now Button */}
          <Button
            style={ButtonStyle.SPECIAL}
            loading={loadingTokenInfo}
            disabled={inputTokenId === undefined}
            onClick={checkNowClicked}
            text={t("projectPage.checkNow")}
          />

          <div className="mb-24">
            {/* Card below Button */}
            {nft ? (
              <div className="mt-12 flex items-center justify-center">
                <img
                  src={convertIpfs(nft.media[0].raw)}
                  className="w-48 rounded-xl object-contain"
                />
              </div>
            ) : (
              <div className="mt-12 flex aspect-square w-full items-center justify-center rounded-xl border border-gray800 bg-gray900">
                (
                <img
                  src="/images/loading.png"
                  alt="spinner"
                  className="h-8 w-8 animate-spin"
                />
                )
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Rewards */}
      <div className="flex w-full flex-col items-center">
        {rewardClaims && rewardClaims.length >= 0 ? (
          <div className="mx-4 -mt-16 grid max-w-4xl grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
            {rewardClaims.map((rewardClaim, i) => {
              if (tokenId !== undefined) {
                const eligible =
                  rewardClaim.reward.tokenIds?.includes(tokenId) ?? false;

                const expired = rewardClaim.reward.status === "Past";
                const claimed =
                  rewardClaim.claims.find((claim) => {
                    return claim.tokenId === tokenId;
                  }) !== undefined;

                return (
                  <ProjectRewardCard
                    key={rewardClaim.reward.id}
                    rewardClaim={rewardClaim}
                    tokenId={String(tokenId ?? "")}
                    connected={account !== undefined}
                    eligible={eligible}
                    state={expired ? "expired" : claimed ? "claimed" : "active"}
                    onClaim={() => setSelectedProductClaim(rewardClaim)}
                    setRewardDetail={setRewardDetail}
                  />
                );
              }
            })}
          </div>
        ) : (
          <h2 className="mt-8 text-3xl font-semibold text-gray900">
            Currently no rewards setup
          </h2>
        )}

        {/* Spacer */}
        <div className="flex h-24 w-full flex-shrink-0" />

        {/* Upcoming Rewards */}
        {upcomingRewardClaims && upcomingRewardClaims.length > 0 && (
          <>
            <div className="mx-4 flex w-full max-w-5xl flex-col items-center px-8 lg:px-24">
              {/* Header */}
              <h2 className="w-full self-start text-left text-3xl font-semibold text-gray900">
                {t("projectPage.upcomingRewards")}
              </h2>
              <p className="w-full text-left text-base font-normal text-gray600">
                {t("projectPage.upcomingRewardsSubtitle")}
              </p>

              {/* Upcoming Rewards */}
              <div className="mx-4 mt-6 flex w-full flex-col gap-6">
                {upcomingRewardClaims
                  ?.sort((a, b) => {
                    return a.reward.claimWindow.start.localeCompare(
                      b.reward.claimWindow.start
                    );
                  })
                  .map((rewardClaim, i) => {
                    return (
                      <>
                        <ProjectUpcomingRewardCard
                          key={rewardClaim.reward.id}
                          reward={rewardClaim.reward}
                          setRewardDetail={setRewardDetail}
                        />
                        {i !== upcomingRewardClaims?.length - 1 && (
                          <div
                            className="self-end"
                            style={{ width: "calc(100% - 184px)" }}
                          >
                            <Separator />
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
            {/* Spacer */}
            <div className="flex h-24 w-full flex-shrink-0" />
          </>
        )}

        {/* Past Rewards */}
        {pastClaims && pastClaims.length > 0 && (
          <>
            <div className="mx-4 flex w-full max-w-5xl flex-col items-center px-8 lg:px-24">
              {/* Header */}
              <h2 className="w-full self-start text-left text-3xl font-semibold text-gray900">
                {t("projectPage.pastRewards")}
              </h2>
              <p className="w-full text-left text-base font-normal text-gray600">
                {t("projectPage.pastRewardsSubtitle")}
              </p>

              {/* Upcoming Rewards */}
              <div className="mx-4 mt-6 flex w-full flex-col gap-6">
                {pastClaims
                  ?.sort((a, b) => {
                    return a.reward.claimWindow.start.localeCompare(
                      b.reward.claimWindow.start
                    );
                  })
                  .map((rewardClaim, i) => {
                    return (
                      <>
                        <ProjectUpcomingRewardCard
                          key={rewardClaim.reward.id}
                          reward={rewardClaim.reward}
                          setRewardDetail={setRewardDetail}
                        />
                        {i !== pastClaims?.length - 1 && (
                          <div
                            className="self-end"
                            style={{ width: "calc(100% - 184px)" }}
                          >
                            <Separator />
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
            {/* Spacer */}
            <div className="flex h-24 w-full flex-shrink-0" />
          </>
        )}
      </div>
      {selectedProductClaim && account && tokenId && projectUser?.id && (
        <ClaimDialog
          open={true}
          selectedProductClaim={selectedProductClaim.reward}
          account={account}
          userId={projectUser.id}
          tokenId={tokenId}
          onClose={() => setSelectedProductClaim(undefined)}
          onClaimed={() => {
            setSelectedProductClaim(undefined);
            queryNFT(); // reload
            setClaimSuccessFull(true);
          }}
        />
      )}

      <AppearingDialog
        key="claimSuccess"
        open={claimSuccessFull}
        onClose={() => setClaimSuccessFull(false)}
      >
        <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
          <div className="flex h-3/4 w-full flex-1 flex-col items-start justify-center text-left">
            {/* Title */}
            <p className="w-full text-left text-lg font-semibold text-gray900 line-clamp-1 md:text-xl">
              {t("projectPage.claimSuccess")}
            </p>
            <p className="mt-2 flex text-base font-normal text-gray600 line-clamp-2 md:text-lg">
              {t("projectPage.claimSuccessSubtitle")}
            </p>
          </div>
        </div>
      </AppearingDialog>

      <AppearingDialog
        key={rewardDetail?.id}
        open={rewardDetail !== undefined}
        onClose={() => setRewardDetail(undefined)}
      >
        <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
          <div className="flex h-3/4 w-full flex-1 flex-col items-start justify-center text-left">
            {/* Title */}
            <p className="w-full text-left text-lg font-semibold text-gray900 line-clamp-1 md:text-xl">
              {rewardDetail?.title}
            </p>
            <p className="mt-2 flex text-base font-normal text-gray600 line-clamp-2 md:text-lg">
              {rewardDetail?.description}
            </p>
            {rewardDetail?.products?.map((product) => {
              return (
                <div className="mt-4 w-full">
                  <p className="font-semibold text-gray600">{product.title}</p>
                  <p className="text-gray600">{product.description}</p>
                  <div className="mt-2 flex h-20 justify-start gap-4">
                    {product.imageIds?.map((imageId) => {
                      return (
                        <div className="">
                          <AnonymousPhoto id={imageId}></AnonymousPhoto>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="flex text-sm font-semibold text-gray600 line-clamp-2">
              {t("projectPage.rewardClaimWindow") + ": "}
              {shortDate(new Date(rewardDetail?.claimWindow.start ?? ""))}
              {rewardDetail?.claimWindow.end
                ? " - " +
                  shortDate(new Date(rewardDetail?.claimWindow?.end ?? ""))
                : undefined}
            </div>
          </div>
        </div>
      </AppearingDialog>
    </div>
  );
}

//Upcoming Reward
type ProjectUpcomingRewardCardProps = {
  reward: RewardDetail;
  setRewardDetail: (reward: RewardDetail) => void;
};

const ProjectUpcomingRewardCard: React.FC<ProjectUpcomingRewardCardProps> = ({
  reward,
  setRewardDetail,
}) => {
  return (
    <div className="flex w-full flex-row gap-6 overflow-hidden">
      {/* Calendar Thing */}
      <div className="flex aspect-square h-40 flex-shrink-0 flex-col items-center justify-center rounded-xl border border-gray200 bg-white text-6xl font-bold text-black">
        <h1>{new Date(reward.claimWindow?.start ?? "").getDate()}</h1>
        <p className="-mt-1 text-xl font-semibold text-accent700">
          {new Date(reward.claimWindow?.start ?? "").toLocaleString("default", {
            month: "long",
          })}
        </p>
      </div>
      {/* Content */}
      <div className="flex h-40 w-full flex-1 flex-col items-start justify-center text-left">
        {/* Title */}
        <p className="w-full text-left text-lg font-medium text-gray900 line-clamp-1">
          {reward.title}
        </p>

        <p className="mt-2 flex text-base font-normal text-gray600 line-clamp-2">
          {reward.description}
        </p>

        <p
          className="mt-1.5 w-full cursor-pointer text-left text-sm font-semibold text-accent700 hover:opacity-70"
          onClick={() => setRewardDetail(reward)}
        >
          {t("projectPage.readMore")}
        </p>
      </div>
    </div>
  );
};

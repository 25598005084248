import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import TextFieldSetting from "../Components/UI/TextFieldSetting";
import { showError } from "../util";

export default function ResetPassword() {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { api } = useAuth();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const goToLoginPage = () => navigate("/login");

  return (
    <div className="flex h-screen min-h-screen w-full flex-col items-center justify-center bg-white">
      {/* Logo */}
      <img src="/images/login-logo.svg" className="h-8 w-full object-contain" />

      <div className="mt-8 text-left">
        {/* Tile */}
        <h3 className="text-center text-3xl font-semibold text-gray900">
          {t("login.newPassword.headling")}
        </h3>
      </div>
      {/* Email Password Fields */}
      <div className="mt-8 w-96 px-4 text-left">
        <input
          name="password"
          type="password"
          placeholder={t("login.enterYourNewPassword")}
          className="mt-1 w-full rounded-lg border border-gray300 bg-white px-3.5 py-2.5 text-base font-normal text-gray-900 focus:z-10 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-accent600"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
        />
        {/* Login Button */}
        <input
          type="submit"
          value={t("login.setPasswordButton")}
          name="submit"
          className="mt-6 w-full cursor-pointer rounded-lg bg-accent600 py-2.5 text-base font-semibold text-white hover:opacity-70"
          onClick={async () => {
            setLoading(true);
            try {
              await api.setPassword({
                token: token ?? undefined,
                password: password,
              });
              goToLoginPage();
            } catch (response: any) {
              showError(response);
            }
            setLoading(false);
          }}
        />
      </div>
    </div>
  );
}

import React from "react";

type Props = {
  text: string;
};

export default function HeaderLabel({ text }: Props) {
  return (
    <h2 className="headline-gradient font-spaceGrotesk text-lg font-bold md:text-4xl">
      {text}
    </h2>
  );
}

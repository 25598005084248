import React, { useEffect, useState } from "react";
import { useAPIKey } from "../../API/APIKeyContext";
import { classNames } from "../../util";

type Props = {
  className?: string;
  id: string | undefined;
  file?: File | undefined;
};

export const AnonymousPhoto: React.FC<Props> = ({ className, id, file }) => {
  const [imgSrc, setImgSrc] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const { api } = useAPIKey();

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      api
        .getImageAsResource1(id)
        .then((res) => {
          setImgSrc(URL.createObjectURL(res.data as any));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (file) {
      setImgSrc(URL.createObjectURL(file));
    }
  }, [id, file]);

  return isLoading ? (
    <div
      className={classNames(
        "h-full w-full animate-pulse rounded-lg bg-gray50",
        className
      )}
    />
  ) : (
    <img
      className={classNames(
        "h-full w-full bg-gradient-to-r from-gray-50 to-gray-300 object-cover",
        className
      )}
      src={imgSrc}
      alt=""
      key={imgSrc}
    />
  );
};

import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIKeyContext from "./API/APIKeyContext";
import "./App.css";
import SidebarLayout from "./Components/SidebarLayout";
import Admin from "./pages/Admin";
import AuthGuard from "./pages/AuthGuard";
import Claims from "./pages/Claims";
import Confirm from "./pages/Confirm";
import HandleAuthCode from "./pages/HandleAuthCode";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NewRewards from "./pages/NewRewards";
import Products from "./pages/Products";
import ProjectPage from "./pages/ProjectPage";
import ResetPassword from "./pages/ResetPassword";
import Rewards from "./pages/Rewards";
import Settings from "./pages/Settings";

function App() {
  return (
    <div className="App bg-background font-sans min-h-screen min-w-full text-primaryText">
      <ToastContainer limit={1} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <AuthGuard>
                <SidebarLayout />
              </AuthGuard>
            </>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/rewards/new" element={<NewRewards />} />
          <Route path="/claims" element={<Claims />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/handle-auth" element={<HandleAuthCode />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/project/*"
          element={
            <APIKeyContext>
              <ProjectPage />
            </APIKeyContext>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

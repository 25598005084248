import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ArrowDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import React, { Fragment } from "react";
import { classNames } from "../../util";

type Props = {
  label: string;
  options: string[];
  selected?: number;
  setSelected: (index: number) => void;
  defaultText?: string;
};

export default function DropdownSelector({
  label,
  options,
  selected,
  setSelected,
  defaultText = "Select an option",
}: Props) {
  const selectedOption = selected ? options[selected] : undefined;

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="mb-2 block text-sm font-medium text-gray700">
            {label}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="shadow-xs relative w-full cursor-pointer rounded-lg border border-gray300 py-2 pl-3 pr-10 text-left shadow-sm hover:opacity-70  focus:outline-none sm:text-sm">
              <span
                className={classNames(
                  "block truncate text-base font-medium",
                  selectedOption ? "text-gray900" : "text-gray500"
                )}
              >
                {selectedOption || defaultText}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-4 w-4 text-gray500"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg border border-gray200 bg-white py-1 text-base font-medium shadow-lg ring-1 ring-transparent focus:outline-none sm:text-sm">
                {options.map((option, i) => (
                  <Listbox.Option
                    key={option}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-accent text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                        i === 0 ? "opacity-50" : ""
                      )
                    }
                    value={i}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-medium",
                            "block truncate text-base"
                          )}
                        >
                          {i === 0 ? defaultText : option}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-accent",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            {i !== 0 && (
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

import { classNames } from "../../util";
import React from "react";
import { motion } from "framer-motion";

type Props = {
  className?: string;
  options: { title: string; additionalInfo?: string }[];
  onChange: (index: number) => void;
  activeIndex: number;
};

export const OptionBar: React.FC<Props> = ({
  className,
  options,
  onChange,
  activeIndex,
}) => {
  return (
    <div className="grid grid-cols-2 items-center gap-2 md:grid-cols-3 lg:grid-cols-4">
      {options.map((option, index) => (
        <motion.div
          className={classNames(
            "flex cursor-pointer flex-row items-center gap-2 whitespace-nowrap rounded-md px-8 py-2 text-sm font-semibold transition-all hover:opacity-70",
            index === activeIndex
              ? "bg-accent50 text-accent"
              : "bg-transparent text-gray500"
          )}
          key={JSON.stringify(option)}
          onClick={() => onChange(index)}
        >
          <p className="flex-0 flex">{option.title}</p>
          <motion.div
            className="bg-accent200 w-auto origin-center items-center justify-center rounded-full object-center px-2 py-0.5 text-xs font-normal text-accent"
            initial={{
              opacity: option.additionalInfo && index === activeIndex ? 1 : 0,
              width:
                option.additionalInfo && index === activeIndex
                  ? option.additionalInfo.length * 8 + 16
                  : 0,
              paddingLeft:
                option.additionalInfo && index === activeIndex ? 8 : 0,
              paddingRight:
                option.additionalInfo && index === activeIndex ? 8 : 0,
            }}
            animate={{
              opacity: option.additionalInfo && index === activeIndex ? 1 : 0,
              width:
                option.additionalInfo && index === activeIndex
                  ? option.additionalInfo.length * 8 + 16
                  : 0,
              paddingLeft:
                option.additionalInfo && index === activeIndex ? 8 : 0,
              paddingRight:
                option.additionalInfo && index === activeIndex ? 8 : 0,
            }}
          >
            {option.additionalInfo ?? ""}
          </motion.div>
        </motion.div>
      ))}
    </div>
  );
};

import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../API/AuthContext";
import { useUser } from "../API/UserContext";
import { ConfirmDialog } from "../Components/Dialogs/ConfirmDialog";
import HeaderLabel from "../Components/Label/HeaderLabel";
import Button, { ButtonStyle } from "../Components/UI/Button";
import Separator from "../Components/UI/Separator";
import { SettingsFileUpload } from "../Components/UI/SettingsFileUpload";
import { SettingsTextInput } from "../Components/UI/SettingsTextInput";
import { useError } from "../Error/ErrorContext";
import ConnectWalletButton from "../web3/ConnectWalletButton";
import { useWeb3 } from "../web3/Web3Context";

/// This site will be opened after login redirect
export default function Settings() {
  const { t } = useTranslation();
  const { api } = useAuth();
  const { refreshUser } = useUser();
  const { connectWallet, account, signMessage } = useWeb3();
  const { setError } = useError();
  const [isSaving, setIsSaving] = useState(false);
  const [isValidatingContract, setValidateContract] = useState(false);

  const [projectName, setProjectName] = useState("");
  const [projectDisplayName, setProjectDisplayName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectContract, setProjectContract] = useState("");
  const [isMetadataLoading, setIsMetadataLoading] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [logo, setLogo] = useState<{
    id: string | undefined;
    file: File | undefined;
  }>({
    id: undefined,
    file: undefined,
  });

  const urlPrefix = process.env.REACT_APP_FRONTEND_URI + "/project/";

  const queryMe = async () => {
    const response = await api.me({ secure: true });

    if (response.data.username) {
      setProjectName(response.data.projectName ?? "");
      setProjectDisplayName(response.data.projectDisplayName ?? "");
      setProjectDescription(response.data.projectDescription ?? "");
      setProjectContract(response.data.contractAddress ?? "");
      setLogo({
        id: response.data.projectImageId,
        file: undefined,
      });
    } else if (response.error) {
      console.error(response.error);
    }
  };

  const validateContract = async () => {
    if (!account) {
      await connectWallet();
    }

    if (account) {
      setValidateContract(true);
      try {
        const nonceResult = await api.getNonce({ walletAddress: account });
        const nonce = nonceResult.data;
        const signature = await signMessage(nonce.message);

        if (signature) {
          const result = await api.verifyContract(
            {
              walletAddress: account,
              signature,
              contractAddress: projectContract,
            },
            { secure: true }
          );
          refreshUser();
        }
      } catch (error) {
        setError(error);
        setProjectContract("");
      }
      setValidateContract(false);
    }
  };

  useEffect(() => {
    queryMe();
  }, []);

  return (
    <div className="flex w-full flex-col items-start">
      <ConfirmDialog
        isOpen={confirmDelete}
        close={() => setConfirmDelete(false)}
        onAction={() => {
          api.delete().then(() => {
            window.location.href = "/";
          });
        }}
        title={t("settings.deleteAccount")}
        body={t("settings.deleteAccountBody")}
        actionText={t("settings.deleteAccountAction")}
      />

      <div className="flex w-full justify-between">
        <HeaderLabel text={t("menu.settings")} />
        <div className="w-48">
          <ConnectWalletButton />
        </div>
      </div>
      <div className="mt-8 flex w-full flex-col gap-8">
        {/* Title */}
        <SettingsTextInput
          title={t("account.projectDisplayName")}
          description={t("account.projectDisplayNameDescription")}
          value={projectDisplayName}
          onChange={setProjectDisplayName}
        />
        {/* Description */}
        <SettingsTextInput
          title={t("account.projectDescription")}
          description={t("account.projectDescriptionDescription")}
          value={projectDescription}
          onChange={setProjectDescription}
          type="textarea"
          placeholder={t("account.projectDescriptionPlaceholder")}
        />
        {/* Smart Contract */}
        <SettingsTextInput
          title={t("account.projectSmartContract")}
          description={t("account.projectSmartContractDescription")}
          value={projectContract}
          onChange={setProjectContract}
        >
          <Button
            text={t("settings.validateContract")}
            loading={isValidatingContract}
            onClick={validateContract}
          />
        </SettingsTextInput>
        <Separator />
        {/* Slug */}
        <SettingsTextInput
          title={t("account.slug")}
          description={t("account.slugDescription")}
          value={projectName}
          onChange={setProjectName}
          prefix={urlPrefix}
        >
          <a
            href={urlPrefix + projectName}
            target="_blank"
            rel="noreferrer"
            className="text-sm font-bold text-accent"
          >
            {urlPrefix + projectName}
          </a>
        </SettingsTextInput>
        <Separator />
        {/* Collection Logo */}
        <SettingsFileUpload
          title={t("account.collectionLogo")}
          description={t("account.collectionLogoDescription")}
          imageId={logo.id}
          imageFile={logo.file}
          onFileSelected={(file) => setLogo({ id: undefined, file: file })}
        />
        <Separator />
      </div>
      <div className="mt-5 flex w-full flex-row justify-between">
        <Button
          text={t("buttons.deleteAccount")}
          style={ButtonStyle.OUTLINED_DANGER}
          icon={<TrashIcon className="button-heroicon-stroke" />}
          onClick={() => {
            setConfirmDelete(true);
          }}
        />

        <div className="flex flex-row gap-4">
          <Button
            text={t("buttons.refreshMetadata")}
            onClick={async () => {
              setIsMetadataLoading(true);
              const respose = await api.refreshProjectMetaData({
                secure: true,
              });
              setIsMetadataLoading(false);
            }}
            loading={isMetadataLoading}
          />

          <Button
            loading={isSaving}
            text={t("buttons.save")}
            onClick={async () => {
              setIsSaving(true);
              const response = await api.updateProject(
                {
                  projectName: projectName,
                  projectDisplayName: projectDisplayName,
                  projectDescription: projectDescription,
                },
                { secure: true }
              );

              //upload logo.file now if it exists
              if (logo.file) {
                await api.setProjectImage(
                  { image: logo.file },
                  { secure: true }
                );
              }

              if (response.ok) {
                queryMe();
                setIsSaving(false);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

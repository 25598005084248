import {
  CalendarIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button, { ButtonStyle } from "../../Components/UI/Button";
import { Photo } from "../../Components/UI/Photo";
import { useError } from "../../Error/ErrorContext";
import { classNames } from "../../util";
import { RewardFormData } from "../NewRewards";

type Props = {
  formData: RewardFormData;
  onSubmit: (data: any) => void;
  startPast: boolean;
  useEndDate: boolean;
  setUseEndDate: React.Dispatch<React.SetStateAction<boolean>>;
  image: File | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
};

export default function PublicInfo({
  formData,
  onSubmit,
  startPast,
  useEndDate,
  setUseEndDate,
  image,
  setImage,
}: Props) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { setError } = useError();

  const errorStyle =
    "border-red-600 text-red-600 focus:border-red-600 focus:ring-red-600";
  const normalStyle = "border-gray300 focus:border-accent700 focus:ring-accent";

  return (
    <div className="mt-14 text-left">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-sm font-medium leading-6 text-gray700">
            {t("rewards.newReward.publicInformation")}
          </h3>
          <p className="text-sm font-normal text-gray600">
            {t("rewards.newReward.publicInformationDescription")}
          </p>
        </div>
        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3">
              <div className="flex w-full flex-row gap-8">
                {image && (
                  <div className="flex h-32 w-32 flex-shrink-0">
                    <Photo id={undefined} file={image} />
                  </div>
                )}
                <div className="relative flex h-32 flex-1 flex-shrink-0 flex-col items-center justify-center rounded-xl border border-gray200 px-8">
                  <div className="flex h-12 w-12 -translate-x-1 -translate-y-1 items-center justify-center rounded-full border-[8px] border-gray50 bg-gray100">
                    <div className="button-heroicon-stroke h-5 w-5 rounded-full text-gray600">
                      <CloudArrowUpIcon className="button-heroicon-stroke" />
                    </div>
                  </div>
                  <p className="mt-3 text-sm font-normal text-gray600">
                    <span className="font-semibold text-accent700">
                      {t("product.clickToUpload")}
                    </span>{" "}
                    {t("product.orDragAndDrop")}
                  </p>
                  <p className="mt-1 text-xs font-normal text-gray600">
                    {t("product.maxImageSize")}
                  </p>

                  <input
                    type="file"
                    className={
                      "absolute inset-0 h-full w-full cursor-pointer opacity-0"
                    }
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files) {
                        setImage(e.target.files[0]);
                      }
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      if (e.dataTransfer.files) {
                        setImage(e.dataTransfer.files[0]);
                      }
                    }}
                  />
                </div>
              </div>

              <label
                htmlFor="title"
                className="mt-6 block text-sm font-medium text-gray700"
              >
                {t("product.title")}
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  id="title"
                  className={classNames(
                    errors["title"] ? errorStyle : normalStyle,
                    "block w-full flex-1 rounded-md border-gray300 focus:border-accent700 focus:ring-accent700 sm:text-sm"
                  )}
                  placeholder={t("reward.title.placeholder")}
                  value={watch("title", formData.title)}
                  {...register("title", { required: true })}
                />
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray700"
            >
              {t("product.description")}
            </label>
            <div className="mt-1">
              <textarea
                id="description"
                rows={3}
                placeholder={t("reward.description.placeholder")}
                className={classNames(
                  errors["description"] ? errorStyle : normalStyle,
                  "block w-full flex-1 rounded-md border-gray300 focus:border-accent700 focus:ring-accent700 sm:text-sm"
                )}
                value={watch("description", formData.description)}
                {...register("description", { required: true })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-sm font-medium leading-6 text-gray700">
            {t("rewards.newReward.claimPeriod")}
          </h3>
          <p className="text-sm font-normal text-gray600">
            {t("rewards.newReward.claimPeriodDescription1")}
            <br />
            <br />
            {t("rewards.newReward.claimPeriodDescription2")}
          </p>
        </div>
        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <div className="grid grid-cols-1 gap-6">
            <div className="col-span-1 grid grid-cols-1 sm:col-span-1 md:grid-cols-2">
              <div>
                <label
                  htmlFor="startClaimPeriod"
                  className="mb-1 block text-sm font-medium text-gray700"
                >
                  {t("rewards.startClaimPeriod")}
                </label>
                <input
                  id="startClaimPeriod"
                  type="datetime-local"
                  disabled={startPast}
                  className={classNames(
                    errors["startClaimPeriod"] ? errorStyle : normalStyle,
                    "rounded-lg border-gray300 px-4 py-1 text-base font-semibold text-primaryText focus:text-primaryText focus:ring-accent"
                  )}
                  value={watch(
                    "claimWindow.start",
                    formData.claimWindow?.start
                  )}
                  {...register("claimWindow.start", { required: true })}
                />
              </div>
              <div className="mt-4 md:mt-0">
                <label
                  htmlFor="endClaimPeriod"
                  className="mb-1 block text-sm font-medium text-gray700"
                >
                  {t("rewards.endClaimPeriod")}
                </label>
                {!useEndDate && (
                  <Button
                    style={ButtonStyle.OUTLINED}
                    icon={<CalendarIcon />}
                    text={t("button.selectDate")}
                    onClick={() => setUseEndDate(true)}
                  />
                )}
                <div
                  className={classNames(
                    "relative",
                    useEndDate ? "flex" : "hidden"
                  )}
                >
                  <input
                    id="endingClaimPeriod"
                    type="datetime-local"
                    className={classNames(
                      errors["endClaimPeriod"] ? errorStyle : normalStyle,
                      "rounded-lg border-gray300 px-4 py-1 text-base font-semibold text-primaryText focus:text-primaryText focus:ring-accent"
                    )}
                    value={watch("claimWindow.end", formData.claimWindow?.end)}
                    {...register("claimWindow.end", {
                      required: useEndDate ? true : false,
                    })}
                  />
                  <button onClick={() => setUseEndDate(false)}>
                    <XMarkIcon className="absolute -top-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 w-full bg-white">
        <div className="flex h-16 items-center justify-end gap-2">
          <Link to="/rewards">
            <Button style={ButtonStyle.OUTLINED} text={t("buttons.cancel")} />
          </Link>
          <Button
            text={t("buttons.nextStep")}
            onClick={handleSubmit(onSubmit)}
            // disabled={
            //   (formData.title ?? "").length <= 0 ||
            //   (formData.description ?? "").length <= 0
            // }
          />
        </div>
      </div>
    </div>
  );
}

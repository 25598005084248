import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";

type Props = {
  title: string;
  subtitle?: string;
  buttonTitle: string;
  onClick: () => void;
};

export default function EmptyAddElement({
  title,
  subtitle = undefined,
  buttonTitle,
  onClick,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="mt-5 flex w-full flex-col items-center justify-center rounded-xl border border-gray200 px-8 py-14 md:col-span-2 md:mt-0">
      <div className="text-center">
        <h3 className="text-lg font-semibold text-gray900">{title}</h3>
        <p className="text-sm font-normal text-gray600">{subtitle}</p>
      </div>
      <div className="mt-8">
        <Button icon={<PlusIcon />} text={buttonTitle} onClick={onClick} />
      </div>
    </div>
  );
}

import { useTransform } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../API/AuthContext";
import HeaderLabel from "../Components/Label/HeaderLabel";
import Button from "../Components/UI/Button";
import { SettingsTextInput } from "../Components/UI/SettingsTextInput";
import { useError } from "../Error/ErrorContext";

type Props = {};

export default function Admin({}: Props) {
  const { t } = useTranslation();

  const { api } = useAuth();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [projectName, setProjectName] = useState("");

  const createUser = async () => {
    setLoading(true);
    try {
      const result = await api.createUser({
        username: email,
        projectName: projectName,
      });
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  return (
    <div className="flex w-full flex-col items-start">
      <div className="mb-8 flex w-full justify-between">
        <HeaderLabel text={t("menu.admin")} />
      </div>
      <div className="mt-8 flex w-full flex-col gap-8">
        {/* Title */}
        <SettingsTextInput
          title={"Email"}
          description={"The users email address"}
          value={email}
          onChange={setEmail}
        />
        <SettingsTextInput
          title={"Project name"}
          description={"The projects name url safe e.g. test-project"}
          value={projectName}
          onChange={setProjectName}
        />
        <div className="flex">
          <Button text="Create user" onClick={createUser} loading={loading} />
        </div>
      </div>
    </div>
  );
}

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { showError } from "../util";
import { getErrorMessageFromResponse } from "./ErrorMap";

type ErrorContext = {
  error: any;
  setError: React.Dispatch<any>;
};

const context = createContext<ErrorContext>(null!);

type Props = PropsWithChildren<{}>;

export function ErrorHandler({ children }: Props) {
  const [error, setError] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      const message = t(getErrorMessageFromResponse(error)) ?? error;
      showError(message);
    }
    setError(undefined);
  }, [error]);

  const ctx = useMemo(() => ({ error, setError }), [error]);
  return <context.Provider value={ctx}>{children}</context.Provider>;
}

export const useError = () => useContext(context);

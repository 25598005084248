import React from "react";
import { classNames } from "../../util";
import ActivityIndicator from "../ActivityIndicator/ActivityIndicator";

export enum ButtonStyle {
  FILLED,
  OUTLINED,
  OUTLINED_DANGER,
  GRAY,
  SPECIAL
}

type Props = {
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: ButtonStyle;
  loading?: boolean;
  icon?: JSX.Element;
};

export default function Button({
  text,
  onClick = undefined,
  disabled = false,
  style = ButtonStyle.FILLED,
  loading = false,
  icon = undefined,
  children = undefined,
}: React.PropsWithChildren<Props>) {
  let styleClasses;
  switch (style) {
    case ButtonStyle.FILLED:
      styleClasses =
        "border border-accent600 bg-accent600 text-white hover:bg-white hover:text-accent600";
      break;
    case ButtonStyle.OUTLINED_DANGER:
      styleClasses =
        "border border-red300 text-red700 hover:bg-red700 hover:text-white shadow-xs hover:border-red700";
      break;
    case ButtonStyle.OUTLINED:
      styleClasses =
        "border border-gray300 text-gray700 hover:bg-white hover:text-accent600 shadow-xs hover:border-accent600";
      break;
    case ButtonStyle.GRAY:
      styleClasses =
        "border border-gray300 bg-gray300 text-primaryText hover:text-accent600";
      break;
      case ButtonStyle.SPECIAL:
        styleClasses = "special-button-gradient mt-4 h-[46px] w-full rounded-lg border border-accent600 text-base font-semibold text-white hover:opacity-70"
  }

  const disabledStyle = disabled ? "opacity-50 cursor-not-allowed" : "";

  return (
    <button
      className={classNames(
        styleClasses,
        disabledStyle,
        "relative transform rounded-lg px-4 py-1 text-base font-semibold transition duration-100 ease-in-out focus:outline-none"
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      <div className="flex items-center justify-center gap-2">
        {icon && <div className="button-heroicon-stroke h-5 w-5">{icon}</div>}
        <p className={classNames(loading ? "invisible" : "")}>{text}</p>
      </div>
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
        {loading && <ActivityIndicator />}
      </div>
    </button>
  );
}

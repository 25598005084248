import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import ActivityIndicator from "../Components/ActivityIndicator/ActivityIndicator";

type Props = {};

export default function HandleAuthCode({}: Props) {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { tokenAuthCode } = useAuth();

  const token = async () => {
    if (code) {
      try {
        await tokenAuthCode(code);
        window.location.href = "/settings";
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    token();
  }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <ActivityIndicator />
    </div>
  );
}

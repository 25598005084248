import { Buffer } from "buffer";
import React, { createContext, useContext, useState } from "react";
import * as sjcl from "sjcl";
import { v4 as uuid } from "uuid";
import { Api } from "./Api";

const CODE_VERIFIER_KEY = `${process.env.REACT_APP_KEY_PREFIX!}.codeverifier`;
const ACCESS_TOKEN_KEY = `${process.env.REACT_APP_KEY_PREFIX!}.access_token`;
const REFRESH_TOKEN_KEY = `${process.env.REACT_APP_KEY_PREFIX!}.refresh_token`;
const AUTHORIZE_CALLED_KEY = `${process.env
  .REACT_APP_KEY_PREFIX!}.authorize_called`;

type Token = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
};

type AuthContext = {
  isLoggedIn: () => boolean;
  authorize: () => void;
  authorizeCalled: () => boolean;
  refreshToken: () => Promise<void>;
  tokenAuthCode: (code: string) => Promise<void>;
  tokenConfirmCode: (code: string) => Promise<void>;
  logout: () => void;
  api: Api;
};

const context = createContext<AuthContext>(null!);

type Props = React.PropsWithChildren<{}>;

export default function AuthContext({ children }: Props) {
  const id = process.env.REACT_APP_CLIENT_ID ?? "";
  const secret = process.env.REACT_APP_CLIENT_SECRET ?? "";
  const redirectUri = process.env.REACT_APP_FRONTEND_URI + "/handle-auth" ?? "";
  const basicAuth = Buffer.from(id + ":" + secret).toString("base64");

  const isLoggedIn = () => {
    return (
      localStorage.getItem(REFRESH_TOKEN_KEY) != undefined &&
      localStorage.getItem(ACCESS_TOKEN_KEY) != undefined
    );
  };

  console.log("LOGGED IN = " + isLoggedIn());

  const securityWorker = () => {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
      },
    };
  };

  const [api] = useState(new Api({ refreshToken, securityWorker }));

  function authorize() {
    const codeVerifier = uuid();
    const sha = sjcl.hash.sha256.hash(codeVerifier);
    const codeChallenge = sjcl.codec.base64url.fromBits(sha);

    const url =
      api.baseUrl +
      "/oauth/authorize?response_type=code&client_id=" +
      id +
      "&redirect_uri=" +
      redirectUri +
      "&code_challenge_method=SHA256&code_challenge=" +
      codeChallenge;

    localStorage.setItem(AUTHORIZE_CALLED_KEY, "true");
    localStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);
    window.location.href = url;
  }

  async function refreshToken() {
    const url = api.baseUrl + "/oauth/token";
    const refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (refresh_token) {
      var details = {
        grant_type: "refresh_token",
        client_id: id,
        refresh_token,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Basic " + basicAuth,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: new URLSearchParams(details),
      });

      const data = await response.json();

      if (response.ok) {
        const token = data as Token;
        localStorage.setItem(ACCESS_TOKEN_KEY, token.access_token);
        localStorage.setItem(REFRESH_TOKEN_KEY, token.refresh_token);
      } else {
        // error
        logout();
      }
    } else {
      // no refresh token
      throw false;
    }
  }

  function authorizeCalled(): boolean {
    const authorizeCalled = localStorage.getItem(AUTHORIZE_CALLED_KEY);
    console.log("Authorize called: " + authorizeCalled);
    return authorizeCalled == "true";
  }

  async function tokenAuthCode(authCode: string) {
    localStorage.removeItem(AUTHORIZE_CALLED_KEY);

    const url = api.baseUrl + "/oauth/token";
    const codeVerifier = localStorage.getItem(CODE_VERIFIER_KEY) ?? "";

    var details = {
      grant_type: "authorization_code",
      client_id: id,
      redirect_uri: redirectUri,
      code: authCode,
      code_verifier: codeVerifier,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Basic " + basicAuth,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams(details),
    });

    const data = await response.json();

    if (response.ok) {
      const token = data as Token;
      localStorage.setItem(ACCESS_TOKEN_KEY, token.access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, token.refresh_token);
    } else {
      // error
      logout();
    }
  }

  async function tokenConfirmCode(confirmationCode: string) {
    const url = api.baseUrl + "/oauth/token";

    var details = {
      grant_type: "confirmation_code",
      client_id: id,
      redirect_uri: redirectUri,
      code: confirmationCode,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Basic " + basicAuth,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: new URLSearchParams(details),
    });

    const data = await response.json();
    if (response.ok) {
      const token = data as Token;
      localStorage.setItem(ACCESS_TOKEN_KEY, token.access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, token.refresh_token);
    } else {
      // error
    }
  }

  async function logout() {
    localStorage.clear();
    try {
      window.location.href = api.baseUrl + "/logout";
    } catch (error) {}
  }

  return (
    <context.Provider
      value={{
        isLoggedIn,
        authorize,
        authorizeCalled,
        refreshToken,
        tokenAuthCode,
        tokenConfirmCode,
        logout,
        api,
      }}
    >
      {children}
    </context.Provider>
  );
}

export const useAuth = () => useContext(context);

import {
  DocumentDuplicateIcon,
  XCircleIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../API/AuthContext";
import { ClaimDetail } from "../../API/data-contracts";
import { useError } from "../../Error/ErrorContext";
import Button, { ButtonStyle } from "./Button";
import { Photo } from "./Photo";
import Separator from "./Separator";
import SlideOver from "./SlideOver";
import { ClaimUpdate } from "../../API/data-contracts";

type Props = {
  claimDetail?: ClaimDetail;
  isOpen: boolean;
  close: () => void;
  onUpdate: () => void;
};

export default function ClaimDetailSlide({
  claimDetail,
  close,
  isOpen,
  onUpdate,
}: Props) {
  const { t } = useTranslation();
  const { api } = useAuth();
  const { setError } = useError();

  async function setStatus(status: ClaimUpdate["status"]) {
    if (claimDetail?.id) {
      try {
        await api.updateClaim(claimDetail.id, { status });
        onUpdate();
      } catch (error) {
        setError(error);
      }
    }
  }

  function copy() {
    //copy address to clipboard
    const address =
      claimDetail?.contactInformation.address?.street +
      ", " +
      claimDetail?.contactInformation.address?.postCode +
      " " +
      claimDetail?.contactInformation.address?.city;
    navigator.clipboard.writeText(address);
  }

  async function cancelItem() {
    if (claimDetail?.id) {
      try {
        await api.updateClaim(claimDetail.id, { status: "Cancelled" });
        onUpdate();
      } catch (error) {
        setError(error);
      }
    }
  }

  const header = (
    <div className="flex w-full flex-col p-6">
      <div className="flex flex-row items-center gap-4">
        <div className="flex aspect-square h-10 items-center justify-center rounded-full border-[6px] border-accent50 bg-accent100">
          <TicketIcon className="h-5 w-5 text-accent" />
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray900">
            {claimDetail?.contactInformation.firstName}{" "}
            {claimDetail?.contactInformation.lastName}
          </h2>
          <p className="text-sm font-normal text-gray600">
            Claim for reward {claimDetail?.reward.title}
          </p>
        </div>
      </div>
      {claimDetail?.status !== "Cancelled" && (
        <button
          className="mt-3 flex w-full items-center justify-center gap-2 rounded-lg bg-red25 px-4 py-1 text-base font-semibold text-red700 hover:opacity-70"
          onClick={cancelItem}
        >
          <XCircleIcon className="button-heroicon-stroke h-5 w-5" />
          {t("buttons.cancelThisClaim")}
        </button>
      )}
    </div>
  );

  return (
    <SlideOver close={close} isOpen={isOpen} title={header}>
      <div className="flex h-full w-full flex-1 flex-col">
        <Separator />
        <div className="flex w-full flex-row items-end p-6">
          <div className="flex flex-1 flex-col">
            <h4 className="text-sm font-medium text-gray700">
              {t("claim.email")}
            </h4>
            <p className="mb-6 text-sm font-normal text-gray600">
              {claimDetail?.contactInformation?.email ?? "-"}
            </p>
            <h4>{t("claim.address")}</h4>
            <p className="text-sm font-normal text-gray600">
              {claimDetail?.contactInformation?.address?.street ?? ""}
            </p>
            <p className="text-sm font-normal text-gray600">
              {claimDetail?.contactInformation?.address?.postCode ?? ""}{" "}
              {claimDetail?.contactInformation?.address?.city ?? ""}
            </p>
          </div>
          <div className="h-fit">
            <Button
              text={t("buttons.copy")}
              icon={
                <DocumentDuplicateIcon className="button-heroicon-stroke" />
              }
              style={ButtonStyle.OUTLINED}
              onClick={copy}
            />
          </div>
        </div>
        <Separator />
        <div className="p-6">
          {claimDetail?.reward && (
            <>
              <h4 className="text-sm font-medium text-gray700">
                {t("claim.reward")}
              </h4>
              <p className="mb-6 text-sm font-normal text-gray600">
                {claimDetail?.reward.title}
              </p>
            </>
          )}

          {claimDetail?.reward?.products &&
            claimDetail.reward.products.length > 0 && (
              <h4 className="text-sm font-medium text-gray700">
                {t("claim.products")}
              </h4>
            )}
          <div className="flex flex-col text-sm font-normal text-gray600">
            {claimDetail?.reward?.products &&
              claimDetail.reward.products.length > 0 &&
              claimDetail?.reward.products.map((p) => (
                <>
                  <div className="flex flex-row gap-3 py-2">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Photo id={p.imageIds?.[0] ?? ""} />
                    </div>
                    <div className="flex flex-1 flex-col items-start justify-center gap-0 overflow-hidden text-ellipsis">
                      <p className="text-left text-sm font-medium text-gray700">
                        {p.title}
                      </p>
                      <p className="text-left text-sm font-normal text-gray600 line-clamp-1">
                        {p.description}
                      </p>
                    </div>
                  </div>
                  <Separator />
                </>
              ))}
          </div>
        </div>

        {/* fill space */}
        <div className="flex flex-1" />
        <Separator />
        <div className="mx-6 mt-6 flex flex-row items-center justify-end gap-3">
          <Button
            text={t("buttons.cancel")}
            style={ButtonStyle.OUTLINED}
            onClick={close}
          />
          <Button
            text={
              claimDetail?.status !== "Claimed"
                ? t("buttons.reopen")
                : t("buttons.markAsCompleted")
            }
            style={ButtonStyle.FILLED}
            onClick={() => {
              setStatus(
                claimDetail?.status !== "Claimed" ? "Claimed" : "Completed"
              );
            }}
          />
        </div>
      </div>
    </SlideOver>
  );
}

import { ArrowUpIcon } from "@heroicons/react/24/outline";
import React from "react";
import Tag from "./Tag";

type Props = {
  className?: string;
  title: string;
  color: "accent" | "green" | "blue";
  value: number;
  isLoading?: boolean;
  percentage?: number;
  icon: JSX.Element;
};

export const DashboardCards: React.FC<Props> = ({
  className,
  title,
  color,
  value,
  isLoading,
  percentage,
  icon,
}) => {
  return (
    <div
      className={
        "flex flex-col rounded-xl border border-gray200 py-5 px-4 shadow-sm"
      }
    >
      {color === "accent" && (
        <div className="flex h-12 w-12 -translate-x-1 -translate-y-1 items-center justify-center rounded-full border-[8px] border-accent50 bg-accent100">
          <div className="button-heroicon-stroke h-5 w-5 rounded-full text-accent">
            {icon}
          </div>
        </div>
      )}
      {color === "green" && (
        <div className="flex h-12 w-12 -translate-x-1 -translate-y-1 items-center justify-center rounded-full border-[8px] border-green50 bg-green100">
          <div className="button-heroicon-stroke h-5 w-5 rounded-full text-green600">
            {icon}
          </div>
        </div>
      )}
      {color === "blue" && (
        <div className="flex h-12 w-12 -translate-x-1 -translate-y-1 items-center justify-center rounded-full border-[8px] border-blueLight50 bg-blueLight100">
          <div className="button-heroicon-stroke h-5 w-5 rounded-full text-blueLight600">
            {icon}
          </div>
        </div>
      )}

      <p className="mt-4 w-full text-left text-sm font-medium text-gray600">
        {title}
      </p>
      <div className="mt-2 flex flex-row items-center justify-between">
        {isLoading ? (
          <div className="h-[36px] w-1/3 animate-pulse rounded-lg bg-gray50" />
        ) : (
          <p className="text-3xl font-semibold text-gray900">{value}</p>
        )}
        {isLoading ? (
          <div className="h-6 w-8 animate-pulse rounded-lg bg-gray50" />
        ) : (
          percentage !== undefined && (
            <Tag
              text={percentage.toString() + "%"}
              icon={
                percentage > 0 ? (
                  <ArrowUpIcon className="button-heroicon-stroke" />
                ) : undefined
              }
              style={percentage > 0 ? "green" : "gray"}
              className="h-fit"
            />
          )
        )}
      </div>
    </div>
  );
};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Product } from "../../API/data-contracts";
import SelectProductsDialog from "../../Components/Dialogs/SelectProductsDialog";
import Button, { ButtonStyle } from "../../Components/UI/Button";
import EmptyAddElement from "../../Components/UI/EmptyAddElement";
import MoreMenu from "../../Components/UI/MoreMenu";
import NonEmptyAddElement from "../../Components/UI/NonEmptyAddElement";
import { Photo } from "../../Components/UI/Photo";
import { useError } from "../../Error/ErrorContext";
import { RewardFormData } from "../NewRewards";

type Props = {
  formData: RewardFormData;
  onSubmit: (data: Product[]) => void;
  onBack: () => void;
};

export default function AddProducts({ formData, onSubmit, onBack }: Props) {
  const { t } = useTranslation();
  const { setError } = useError();
  const [useEndDate, setUseEndDate] = useState(false);
  const [showProductSelector, setShowProductSelector] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>(
    formData.selectedProducts ?? []
  );

  const errorStyle =
    "border-red-600 text-red-600 focus:border-red-600 focus:ring-red-600";
  const normalStyle = "border-gray-300 focus:border-accent focus:ring-accent";

  return (
    <div className="mt-14 w-full text-left">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-sm font-medium leading-6 text-gray700">
            {t("rewards.newReward.products")}
          </h3>
          <p className="text-sm font-normal text-gray600">
            {t("rewards.newReward.productsSubHeadline")}
          </p>
        </div>
        <div className="col-span-2 flex flex-row flex-wrap gap-8">
          {selectedProducts.map((product) => {
            return (
              <div className="col-span-1 flex h-60 w-60 flex-col rounded-xl border border-secondaryBackground">
                <div className="flex flex-1 overflow-hidden rounded-t-xl">
                  <Photo id={product.imageIds?.[0]} />
                </div>
                <div className="flex flex-shrink-0 items-center justify-between p-4">
                  <p className="text-lg font-medium text-gray900">
                    {product.title}
                  </p>
                  <MoreMenu
                    elements={[
                      {
                        function: () => {
                          setSelectedProducts(
                            selectedProducts.filter((p) => p.id !== product.id)
                          );
                        },
                        text: t("buttons.delete"),
                      },
                    ]}
                  />
                </div>
              </div>
            );
          })}
          {selectedProducts.length > 0 ? (
            <div className="col-span-1">
              <NonEmptyAddElement
                title={t("rewards.addMoreProducts")}
                subtitle={t("rewards.youCanAddMultiple")}
                onClick={() => setShowProductSelector(true)}
              />
            </div>
          ) : (
            <EmptyAddElement
              title={t("rewards.noProductsAdded")}
              subtitle={t("rewards.noProductsSubHeadline")}
              buttonTitle={t("buttons.addProduct")}
              onClick={() => setShowProductSelector(true)}
            />
          )}
          <SelectProductsDialog
            isOpen={showProductSelector}
            close={(selectedProducts) => {
              if (selectedProducts) {
                setSelectedProducts(selectedProducts);
              }
              setShowProductSelector(false);
            }}
          />
        </div>
      </div>
      <div className="sticky bottom-0 w-full bg-white">
        <div className="flex h-16 items-center justify-end gap-2">
          <Button
            style={ButtonStyle.OUTLINED}
            text={t("buttons.back")}
            onClick={onBack}
          />

          <Button
            text={t("buttons.nextStep")}
            onClick={() => onSubmit(selectedProducts)}
            disabled={selectedProducts.length === 0}
          />
        </div>
      </div>
    </div>
  );
}

import { Combobox } from "@headlessui/react";
import {
  CheckIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../Hooks/useOnClickOutside";
import { classNames } from "../../util";

type Props = {
  options: string[];
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  label: string;
  className?: string;
};

export default function MultiSelector({
  selected,
  setSelected,
  options,
  label,
  className,
}: Props) {
  const selectedStyle = "bg-gray-100";

  const [query, setQuery] = useState("");

  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  const filteredOptions = options.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className={classNames("flex w-full flex-col", className)}>
      <h3 className="mb-2 block text-sm font-medium text-gray700">{label}</h3>

      <div ref={ref}>
        {/* Input field, show selected as small cards */}
        <div className="relative mt-1">
          <div className="relative h-[45px] w-full cursor-pointer overflow-hidden rounded-lg border border-gray300 py-2 px-3 text-left shadow-sm focus:outline-none sm:text-sm">
            <div className="flex flex-row flex-nowrap gap-1.5 overflow-scroll scrollbar-hide">
              {selected.map((item) => (
                <div
                  className="group relative flex flex-shrink-0 rounded-md border border-gray300 px-2 py-0.5 text-sm font-medium text-gray700 hover:opacity-70"
                  onClick={() =>
                    setSelected((prev) => prev.filter((i) => i !== item))
                  }
                >
                  {item}
                  <div className="absolute right-0 top-0 bottom-0 hidden items-center justify-center rounded-r-md bg-gradient-to-r from-transparent via-white/90 to-white py-1 pr-2 pl-10 group-hover:flex">
                    <XMarkIcon className="button-heroicon-stroke h-full text-red700" />
                  </div>
                </div>
              ))}

              {/* Input field */}
              <div className="relative">
                <input
                  className="w-32 flex-shrink-0 rounded-md border border-gray200
                  px-2 py-0.5 outline-none placeholder:text-gray300 focus:border-accent600"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onFocus={() => setOpen(true)}
                  //onBlur={() => setOpen(false)}
                  placeholder={t("button.search")}
                />
                <div className="pointer-events-none absolute right-2 top-0 bottom-0 flex items-center justify-center">
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Dropdown */}
        <motion.div
          className="shadow-xs relative mt-1 flex max-h-64 w-full flex-col gap-1 overflow-scroll rounded-lg border border-gray200 bg-white py-1.5 px-1.5 shadow-lg sm:text-sm"
          animate={{
            height: open && filteredOptions.length > 0 ? "auto" : 0,
            opacity: open && filteredOptions.length > 0 ? 1 : 0,
          }}
          initial={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {filteredOptions.map((option) => (
            <div
              className={classNames(
                "flex flex-shrink-0 cursor-pointer items-center justify-between rounded-md px-2 py-2.5",
                selected.includes(option) ? "bg-gray50" : ""
              )}
              onClick={() => {
                if (selected.includes(option)) {
                  setSelected((prev) => prev.filter((o) => o !== option));
                } else {
                  setSelected((prev) => [...prev, option]);
                }
              }}
            >
              <p className="text-base font-medium text-gray900">{option}</p>
              {selected.includes(option) && (
                <CheckIcon className="button-heroicon-stroke mr-2 w-4 text-accent600" />
              )}
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../API/AuthContext";
import { Product } from "../../API/data-contracts";
import { useError } from "../../Error/ErrorContext";
import Button, { ButtonStyle } from "../UI/Button";
import Modal from "../../GeneralComponents/Modal";
import { Photo } from "../UI/Photo";
import Separator from "../UI/Separator";

type Props = {
  isOpen: boolean;
  close: (products?: Product[]) => void;
};

export default function SelectProductsDialog({ isOpen, close }: Props) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const { setError } = useError();
  const { api } = useAuth();

  const [products, setProducts] = useState<Product[]>();

  const fetchProducts = async () => {
    const response = await api.getUserProducts({ secure: true });
    if (response.ok) {
      setProducts(
        response.data.sort((a, b) => {
          return a.title.localeCompare(b.title);
        }) ?? []
      );
    } else if (response.error) {
      setError(response.error);
    }
  };

  const errorStyle =
    "border-red-600 text-red-600 focus:border-red-600 focus:ring-red-600";
  const normalStyle = "border-gray300 focus:border-accent focus:ring-accent";

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Modal
      title={t("product.addHeadline")}
      subTitle={t("product.addSubHeadline")}
      isOpen={isOpen}
      closeModal={() => close(undefined)}
    >
      <>
        <div className="flex max-h-96 w-full flex-col overflow-scroll">
          {products?.map((product, i) => {
            const checkboxId = `checkbox-${i}`;
            return (
              <>
                {i > 0 && <Separator key={`separator-${i}`} />}

                <button
                  key={`button-${i}`}
                  className="my-2 flex w-full flex-shrink-0 items-center px-12 text-left"
                  onClick={() => setValue(checkboxId, !getValues(checkboxId))}
                >
                  <input
                    type="checkbox"
                    className="mr-12 h-5 w-5 rounded-md border-gray300 text-accent700 focus:ring-accent700"
                    {...register(checkboxId)}
                  />
                  <div className="flex flex-row">
                    <div className="mr-3 h-10 w-10 overflow-hidden rounded-md border border-gray300">
                      <Photo id={product.imageIds?.[0]} />
                    </div>
                    <div className="flex flex-col">
                      <h3 className="text-sm font-medium text-gray900">
                        {product.title}
                      </h3>
                      <p className="text-xs font-normal text-gray600">
                        {product.description}
                      </p>
                    </div>
                  </div>
                </button>
              </>
            );
          })}
        </div>
        <Separator />
        <div className="flex justify-end gap-2 p-4">
          <Button
            text={t("buttons.cancel")}
            style={ButtonStyle.OUTLINED}
            onClick={() => close(undefined)}
          />
          <Button
            text={t("buttons.addProduct")}
            style={ButtonStyle.FILLED}
            onClick={() => {
              const selectedProducts = products?.filter((product, i) => {
                return getValues(`checkbox-${i}`) === true
                  ? product
                  : undefined;
              });
              close(selectedProducts);
            }}
          />
        </div>
      </>
    </Modal>
  );
}

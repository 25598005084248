import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import ActivityIndicator from "../Components/ActivityIndicator/ActivityIndicator";
export default function Confirm() {
  //redirect url looks like this -> https://pmr-frontend.theshipyard.dev/confirm?code=4e309ff5-3929-4cd2-80b3-d9d19eb591b5

  // parse code and use here: Postman -> dummy server -> Oauth token create account confirmation code

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const { tokenConfirmCode } = useAuth();

  const confirm = async () => {
    if (code) {
      try {
        await tokenConfirmCode(code);
        window.location.href = "/";
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    confirm();
  }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <ActivityIndicator />
    </div>
  );
}

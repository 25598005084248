import { t } from "i18next";
import React from "react";
import Modal from "../../GeneralComponents/Modal";
import Button, { ButtonStyle } from "../UI/Button";

type Props = {
  isOpen: boolean;
  close: () => void;
  onAction: () => void;
  title: string;
  body?: string;
  actionText: string;
  titleCheckmark?: boolean;
};

export const ConfirmDialog: React.FC<Props> = ({
  isOpen,
  close,
  onAction,
  title,
  body,
  actionText,
  titleCheckmark,
  children,
}) => {
  return (
    <Modal
      title={title}
      titleCheckmark={titleCheckmark}
      subTitle={body}
      isOpen={isOpen}
      closeModal={() => close()}
    >
      {children}
      <div className="flex flex-row justify-end gap-2 p-4">
        <Button
          style={ButtonStyle.OUTLINED}
          onClick={() => close()}
          text={t("buttons.cancel")}
        />
        <Button
          style={ButtonStyle.FILLED}
          onClick={() => onAction()}
          text={actionText}
        />
      </div>
    </Modal>
  );
};

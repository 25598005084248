import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import Separator from "../Components/UI/Separator";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  subTitle?: string;
  titleCheckmark?: boolean;
};

export default function Modal({
  title,
  subTitle,
  isOpen,
  closeModal,
  children,
  titleCheckmark,
}: React.PropsWithChildren<Props>) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                {titleCheckmark ? (
                  <Dialog.Title
                    as="h3"
                    className="flex flex-col px-6 pt-6 pb-5 text-base font-semibold leading-6 text-gray900"
                  >
                    <div className="flex flex-row justify-between">
                      <div className="mb-4 flex aspect-square h-[52px] flex-shrink-0 -translate-x-0.5 -translate-y-0.5 items-center justify-center rounded-full bg-green50 p-2">
                        <div className="flex h-full w-full items-center justify-center rounded-full bg-green100">
                          <CheckCircleIcon className="button-heroicon-stroke h-6 text-green600" />
                        </div>
                      </div>
                      <button
                        className="absolute top-6 right-6 text-primaryText"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </button>
                    </div>
                    {title}
                    {subTitle && (
                      <p className="text-sm font-normal text-gray600">
                        {subTitle}
                      </p>
                    )}
                  </Dialog.Title>
                ) : (
                  <Dialog.Title
                    as="h3"
                    className="px-6 pt-6 pb-5 text-base font-semibold leading-6 text-gray900"
                  >
                    {title}
                    {subTitle && (
                      <p className="text-sm font-normal text-gray600">
                        {subTitle}
                      </p>
                    )}
                    <button
                      className="absolute top-6 right-6 text-primaryText"
                      onClick={closeModal}
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </Dialog.Title>
                )}
                {!titleCheckmark && <Separator />}
                <div className="mt-0">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

import React, { createContext, useContext, useState } from "react";
import { Api } from "./Api";

type APIKeyContext = {
  api: Api;
};

const context = createContext<APIKeyContext>(null!);

type Props = React.PropsWithChildren<{}>;

export default function APIKeyContext({ children }: Props) {
  const securityWorker = () => {
    return {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY!,
      },
    };
  };

  // const [api] = useState(new Api({ securityWorker }));
  const [api] = useState(new Api());

  return (
    <context.Provider
      value={{
        api,
      }}
    >
      {children}
    </context.Provider>
  );
}

export const useAPIKey = () => useContext(context);
